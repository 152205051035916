import * as React from 'react';
import { AbilityType, toAbilityName } from '../../api/DnD/abilities';
import { HeroClass, getACModValue, toClassName } from '../../api/DnD/classes';
import { getInitialHP } from '../../api/DnD/hpCalculator';
import { Race, RaceMods, toRaceName } from '../../api/DnD/races';
import DoubleUpIcon from '../../assets/icons/double-up.svg';
import HeartIcon from '../../assets/icons/heart.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import ShieldIcon from '../../assets/icons/shield.svg';
import { useScrollToElement } from '../../hooks/useScrollToElement';
import { AnalyticsThunk } from '../../redux/analytics/thunks';
import {
  useAbilities,
  useArmorClass,
  useCharacterInfo,
  useHPFormula,
  useRemainingAbilityPoints,
} from '../../redux/character/selectors';
import { CharacterThunks } from '../../redux/character/thunks';
import { PanelsThunk } from '../../redux/panels/thunks';
import { useAppDispatch } from '../../redux/store';
import { toNumWithSign } from '../../utils';
import { CharacterNameSelector } from '../CharacterNameSelector/CharacterNameSelector';
import { AvatarPreloader } from '../Preloaders';
import { ShareCharacterButton } from '../ShareCharacter/ShareCharacterButton';
import { Badge } from '../common/Badge/Badge';
import { Button } from '../common/Button/Button';
import { CountSelector } from '../common/CountSelector/CountSelector';
import { Icon } from '../common/Icon';
import { ItemSelector } from '../common/ItemSelector/ItemSelector';
import { Panel, PanelPlacement } from '../common/Panel/Panel';
import { Stack, StackDirection } from '../common/Stack';
import { IconFormula } from './IconFormula';
import { InventoryPreview } from './InventoryPreview';
import { Avatars, getAvatarFace, getFullSizeAvatar } from '../../avatars';
import styles from './CharacterPanel.module.css';

type Props = {
  onDismiss: () => void;
  showAvatar?: boolean;
  panelPlacement?: PanelPlacement;
};

export const CharacterPanel: React.FC<Props> = ({ showAvatar, panelPlacement, onDismiss }) => {
  const dispatch = useAppDispatch();
  const characterInfo = useCharacterInfo();
  const abilities = useAbilities();
  const remainingPoints = useRemainingAbilityPoints();
  const currentHitPoints = useHPFormula();
  const currentArmorClass = useArmorClass();
  const scrollToElement = useScrollToElement('smooth');
  const remainingPointsRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (remainingPointsRef.current) {
      scrollToElement(remainingPointsRef.current);
    }
    //NOTE: remainingPoints is in deps because I want to run scrollToElement when ability points change
  }, [scrollToElement, remainingPoints]);

  //NOTE: check that we use key propertty here to tell react that element needs to be replaced entirely. That is needed to allow animation to play.
  return (
    <Panel
      key="charPanel"
      onDismiss={onDismiss}
      closePlacement="bottomLeft"
      panelPlacement={panelPlacement}
      showOverlay={panelPlacement === 'bottom'}
      toolbarComponent={
        <div className={styles.toolbarContainer}>
          <Button
            className={styles.toolButton}
            tooltip="Randomize All"
            onClick={() => {
              dispatch(CharacterThunks.randomize());
              dispatch(AnalyticsThunk.characterRandomizeClicked());
            }}>
            Randomize
          </Button>
          <Button tooltip="Open help" onClick={() => dispatch(PanelsThunk.pushPanel('left', 'help'))}>
            <HelpIcon width={32} height={32} />
          </Button>
          <ShareCharacterButton />
        </div>
      }>
      <div className={styles.container}>
        {/* Avatar */}
        {showAvatar && (
          <>
            <AvatarPreloader />
            <ItemSelector
              items={Avatars}
              currentItem={characterInfo.avatarFileName}
              selectorTitle="Select Avatar"
              displayAs="grid"
              onItemChanged={(avatarFileName) => dispatch(CharacterThunks.setCharacter({ avatarFileName }))}
              renderItem={(item, inSelector) =>
                inSelector ? (
                  <div className={styles.avatarBack}>
                    <Icon url={getFullSizeAvatar(item)} width={160} height={160} />
                  </div>
                ) : (
                  <div className={styles.avatarBack}>
                    <Icon url={getAvatarFace(item)} width={96} height={96} />
                  </div>
                )
              }
            />
          </>
        )}

        {/* Name */}
        <CharacterNameSelector />

        <Stack direction={StackDirection.Horizontal} justifyContent="space-around" alignItems="center">
          {/* Hit Points */}
          <div style={{ flex: 1 }}>
            <IconFormula title="Hit Points" iconSource={HeartIcon} formula={currentHitPoints} />
          </div>

          {/* Inventory Preview */}
          <InventoryPreview mode="grid" />

          {/* Armor Class */}
          <div style={{ flex: 1 }}>
            <IconFormula title="Armor Class" iconSource={ShieldIcon} formula={currentArmorClass} />
          </div>
        </Stack>

        {/* Race */}
        <ItemSelector
          selectorTitle="Select Race"
          items={Object.values(Race)}
          currentItem={characterInfo.race}
          fullWidth={true}
          onItemChanged={(race: Race) => dispatch(CharacterThunks.setCharacter({ race }))}
          renderItem={(item: Race) => (
            <div className={styles.selectorContent}>
              <div className={styles.label}>{toRaceName(item)}</div>
              <div className={styles.raceMods}>{toRaceMods(item)}</div>
            </div>
          )}
        />

        {/* Class */}
        <ItemSelector
          selectorTitle="Select Class"
          items={Object.values(HeroClass)}
          currentItem={characterInfo.class}
          fullWidth={true}
          onItemChanged={(heroClass: HeroClass) => dispatch(CharacterThunks.setCharacter({ class: heroClass }))}
          renderItem={(item: HeroClass) => {
            const acMod = getACModValue(item, abilities);

            return (
              <div className={styles.selectorContent}>
                <div className={styles.label}>{toClassName(item)}</div>
                <div className={styles.classMods}>
                  <span>Hit Points {getInitialHP(item)}</span>
                  {acMod && (
                    <span>
                      , Armor Class + {acMod.ability}({acMod.value})
                    </span>
                  )}
                </div>
              </div>
            );
          }}
        />

        <Stack direction={StackDirection.Vertical} gap={2}>
          {/* Ability Points Title */}
          <div className={styles.abilityPointsHeader}>Ability Points</div>

          {/* Abilities */}
          <div className={styles.abilities}>
            {abilities.valueSeq().map((ability) => (
              <CountSelector
                key={ability.type}
                text={ability.type}
                value={ability.baseValue}
                minValue={8}
                maxValue={ability.costOfIncrease <= remainingPoints ? ability.baseValue + 1 : ability.baseValue}
                onValueChanged={(value) => dispatch(CharacterThunks.setAbility(ability.type, value))}>
                <div className={styles.abilityInfo}>
                  <div>{ability.type}</div>
                  <div>
                    {ability.baseValue}
                    {ability.raceMod > 0 && (
                      <span className={styles.raceMod}>{toNumWithSign(ability.raceMod, true)}</span>
                    )}
                  </div>
                  <div className={styles.mod}>{toNumWithSign(ability.mod)}</div>
                </div>
              </CountSelector>
            ))}
          </div>

          {/* Remining Ability Points Notification */}
          {remainingPoints > 0 && (
            <Stack
              className={styles.bounce}
              direction={StackDirection.Horizontal}
              gap={8}
              alignItems="center"
              justifyContent="center">
              <Badge iconUrl={DoubleUpIcon} />
              <div ref={remainingPointsRef} className={styles.remainingAbilityPoints}>
                You have <strong>{remainingPoints}</strong> Ability Points to spend
              </div>
            </Stack>
          )}
        </Stack>
      </div>
    </Panel>
  );
};

const toModString = (modifiers: Immutable.Map<AbilityType, number> | undefined): string | undefined => {
  return modifiers
    ?.entrySeq()
    .map(([type, value]) => `${toAbilityName(type)} ${value > 0 ? '+' : '='}${value}`)
    .join(', ');
};

const toRaceMods = (race: Race) => {
  if (race === Race.Human) {
    return 'All Abilities +1';
  }

  let mods = toModString(RaceMods.get(race));

  if (race === Race.HillDwarf) {
    mods = mods + ', Hit Points +1';
  }

  return mods;
};
