import Immutable from 'immutable';
import { Ability, AbilityType } from './abilities';
import { DiceType } from './dices';

export enum HeroClass {
  Barbarian = 'Barbarian',
  Bard = 'Bard',
  Cleric = 'Cleric',
  Druid = 'Druid',
  Fighter = 'Fighter',
  Monk = 'Monk',
  Paladin = 'Paladin',
  Ranger = 'Ranger',
  Rogue = 'Rogue',
  Sorcerer = 'Sorcerer',
  Warlock = 'Warlock',
  Wizard = 'Wizard',
}

export type ClassMods = {
  HitDice: DiceType;
  ArmorClass?: AbilityType;
};

export const HeroClassMods = Immutable.Map<HeroClass, ClassMods>([
  [HeroClass.Barbarian, { HitDice: DiceType.d12, ArmorClass: AbilityType.CON }],
  [HeroClass.Bard, { HitDice: DiceType.d8 }],
  [HeroClass.Cleric, { HitDice: DiceType.d8 }],
  [HeroClass.Druid, { HitDice: DiceType.d8 }],
  [HeroClass.Fighter, { HitDice: DiceType.d10 }],
  [HeroClass.Monk, { HitDice: DiceType.d8, ArmorClass: AbilityType.WIS }],
  [HeroClass.Paladin, { HitDice: DiceType.d10 }],
  [HeroClass.Ranger, { HitDice: DiceType.d10 }],
  [HeroClass.Rogue, { HitDice: DiceType.d8 }],
  [HeroClass.Sorcerer, { HitDice: DiceType.d6 }],
  [HeroClass.Warlock, { HitDice: DiceType.d8 }],
  [HeroClass.Wizard, { HitDice: DiceType.d6 }],
]);

export const toClassName = (heroClass: HeroClass): string => {
  switch (heroClass) {
    case HeroClass.Barbarian:
      return 'Barbarian';
    case HeroClass.Bard:
      return 'Bard';
    case HeroClass.Cleric:
      return 'Cleric';
    case HeroClass.Druid:
      return 'Druid';
    case HeroClass.Fighter:
      return 'Fighter';
    case HeroClass.Monk:
      return 'Monk';
    case HeroClass.Paladin:
      return 'Paladin';
    case HeroClass.Ranger:
      return 'Ranger';
    case HeroClass.Rogue:
      return 'Rogue';
    case HeroClass.Sorcerer:
      return 'Sorcerer';
    case HeroClass.Warlock:
      return 'Warlock';
    case HeroClass.Wizard:
      return 'Wizard';
  }
};

export const getClassMods = (heroClass: HeroClass): ClassMods | undefined => HeroClassMods.get(heroClass);

export type ClassACMod = {
  value: number;
  ability: AbilityType;
};

export const getACModValue = (
  heroClass: HeroClass,
  abilities: Immutable.Map<AbilityType, Ability>,
): ClassACMod | undefined => {
  const classMods = getClassMods(heroClass);

  if (classMods?.ArmorClass) {
    const abilityType = classMods.ArmorClass;
    const value = abilities.get(abilityType)?.mod || 0;
    return { value, ability: abilityType };
  }

  return undefined;
};
