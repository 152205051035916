import * as React from 'react';
import BackIcon from '../../../assets/icons/back.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import { Overlay } from '../Overlay/Overlay';
import { Portal } from '../Portal';
import { RoundButton } from '../RoundButton/RoundButton';
import styles from './Panel.module.css';

export type PanelPlacement = 'left' | 'right' | 'bottom' | 'bottomFullHeight';
export type CloseButtonPlacement = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';

type Props = {
  children?: React.ReactNode;
  onDismiss: () => void;
  closePlacement?: CloseButtonPlacement;
  panelPlacement?: PanelPlacement;
  showOverlay?: boolean;
  panelStyle?: 'floating' | 'cover';
  toolbarComponent?: React.ReactNode;
  backInsteadClose?: boolean;
};

const leftStyle: React.CSSProperties = { flexDirection: 'row-reverse' };
const rightStyle: React.CSSProperties = { flexDirection: 'row' };

export const Panel: React.FC<Props> = ({
  children,
  closePlacement,
  panelPlacement,
  showOverlay,
  panelStyle = 'floating',
  toolbarComponent,
  backInsteadClose,
  onDismiss,
}) => (
  <Portal>
    <div className={getOuterStyle(panelPlacement)}>
      {showOverlay && <Overlay onDismiss={onDismiss} />}
      <div className={getPanelStyle(panelPlacement, closePlacement, panelStyle)}>
        <div className={styles.content}>{children}</div>
        <div className={styles.toolbar} style={getCloseStyle(closePlacement)}>
          {toolbarComponent ? <>{toolbarComponent}</> : <div className={styles.toolbarPlaceholder} />}
          <RoundButton
            imageUrl={backInsteadClose ? BackIcon : CloseIcon}
            className={styles.closeButton}
            onClick={onDismiss}
            iconWidth={24}
            iconHeight={24}
          />
        </div>
      </div>
    </div>
  </Portal>
);

const getCloseStyle = (placement?: CloseButtonPlacement) => {
  return placement === 'bottomLeft' || placement === 'topLeft' ? leftStyle : rightStyle;
};

const getOuterStyle = (panelPlacement?: PanelPlacement) => {
  switch (panelPlacement) {
    case 'left':
      return `${styles.outer} ${styles.outerLeft}`;
    case 'right':
      return `${styles.outer} ${styles.outerRight}`;
    case 'bottom':
    case 'bottomFullHeight':
    default:
      return `${styles.outer} ${styles.outerBottom}`;
  }
};

const getPanelStyle = (
  panelPlacement?: PanelPlacement,
  closePlacement?: CloseButtonPlacement,
  panelStyle?: 'floating' | 'cover',
) => {
  const panelStyles: string[] = [
    styles.panel,
    closePlacement === 'topLeft' || closePlacement === 'topRight' ? styles.topToolbar : styles.bottomToolbar,
  ];

  if (panelStyle === 'floating' && panelPlacement !== 'bottom' && panelPlacement !== 'bottomFullHeight') {
    panelStyles.push(styles.floating);
  } else if (panelStyle === 'cover') {
    panelStyles.push(styles.cover);
  }

  switch (panelPlacement) {
    case 'left':
      panelStyles.push(styles.panelLeft);
      break;
    case 'right':
      panelStyles.push(styles.panelRight);
      break;
    case 'bottomFullHeight':
      panelStyles.push(styles.panelBottom, styles.fullScreen);
      break;
    case 'bottom':
    default:
      panelStyles.push(styles.panelBottom);
      break;
  }

  return panelStyles.join(' ');
};
