import Immutable from 'immutable';
import { createReducer, describeAction } from 'ts-describe-action';
import { CharacterInfo, CharacterState } from '../state';
import { ActionTypes } from '../actionTypes';
import { HeroStorage } from '../../api/heroStorage';
import { Reducer } from 'redux';
import { getRandomAbilities } from '../../api/DnD/abilityScoreCalculator';
import { getRandomCharacterInfo } from '../../api/DnD/characterGenerator';
import { AbilityType } from '../../api/DnD/abilities';
import { getRandomItem } from '../../utils/arrayUtils';
import { Avatars } from '../../avatars';

const setAbilities = describeAction(
  'SET_ABILITIES',
  (prev: CharacterState, abilities: Immutable.Map<AbilityType, number>): CharacterState => ({
    ...prev,
    abilities,
  }),
);

const setCharacterInfo = describeAction(
  'SET_CHARACTER_INFO',
  (prev: CharacterState, characterInfo: CharacterInfo): CharacterState => ({
    ...prev,
    characterInfo,
  }),
);

export const CharacterAction = {
  setAbilities,
  setCharacterInfo,
};

export type CharacterActionType = ActionTypes<typeof CharacterAction>;

const getInitialState = (heroStorage: HeroStorage): CharacterState => {
  //Read character from HeroStorage or create random one
  let characterInfo = heroStorage.readCharacterInfo();
  if (!characterInfo) {
    characterInfo = getRandomCharacterInfo();

    heroStorage.saveCharacterInfo(characterInfo);
  } else if (!characterInfo.avatarFileName) {
    //NOTE: if there is no avatar assigned, let's assign random one
    characterInfo = {
      ...characterInfo,
      avatarFileName: getRandomItem(Avatars),
    };

    heroStorage.saveCharacterInfo(characterInfo);
  }

  let abilities = heroStorage.readAbilities();
  if (!abilities) {
    abilities = getRandomAbilities();
    heroStorage.saveAbilities(abilities);
  }

  return {
    abilities,
    characterInfo,
  };
};

export const createCharacterReducer = (heroStorage: HeroStorage): Reducer<CharacterState> =>
  createReducer(Object.values(CharacterAction), getInitialState(heroStorage));
