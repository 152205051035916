import { MaterialOptions } from 'cannon-es';

export const BodyMaterials: Record<string, MaterialOptions> = {
  Dice: 'diceBodyMaterial',
  Floor: 'floorBodyMaterial',
  Wall: 'barrierBodyMaterial',
};

export enum BodyNames {
  Dice = 'Dice',
  Floor = 'Floor',
  Wall = 'Wall',
}

export const FACE_TEXTS = [
  ' ',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6.',
  '7',
  '8',
  '9.',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
];
