import {
  CollideEvent,
  ConvexPolyhedronArgs,
  ConvexPolyhedronProps,
  PublicApi,
  Quad,
  Triplet,
  useConvexPolyhedron,
} from '@react-three/cannon';
import { Instance } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { BufferGeometry, Clock, MathUtils, Mesh } from 'three';
import { DiceType } from '../../api/DnD/dices';
import { DiceDescription, DiceInfo } from './diceTypes';
import { BodyMaterials, BodyNames } from './r3fConstants';
import { DiceSounds, playRandomSound } from './sounds';
import { randomizeVelocity } from './utils';

const SOUND_DELAY_MS = 300;
const MIN_SOUND_VELOCITY = 1; // Minimal velocity that is enough to play a sound
const MIN_SOUND_VOLUME = 0.5;
const MAX_SOUND_VOLUME = 1;

export type DiceFrameCallback = (
  diceType: DiceType,
  instanceIndex: number,
  clock: Clock,
  api: PublicApi,
  position: Triplet | undefined,
  velocity: Triplet | undefined,
  quaternion: Quad | undefined,
) => void;

export type DiceStartCallback = (diceType: DiceType, instanceIndex: number) => void;
export type DiceStopCallback = (diceType: DiceType, instanceIndex: number, rolledValue: number) => void;

type Props = {
  diceInfo: DiceInfo;
  instanceIndex: number;
  position?: Triplet | undefined;
  velocity?: [vx: number, vy: number];
  diceSounds?: DiceSounds;
  onFrame?: DiceFrameCallback;
  onStarted?: DiceStartCallback;
  onStopped?: DiceStopCallback;
};

export const DiceInstance: React.FC<Props> = ({
  diceInfo,
  instanceIndex,
  diceSounds,
  onFrame,
  onStarted,
  onStopped,
}) => {
  const [ref, api] = useDiceBody(diceInfo.args, diceInfo.description.mass, diceSounds);
  const diceType = diceInfo.description.type;

  const positionRef = useRef<Triplet | undefined>();
  const velocityRef = useRef<Triplet | undefined>();
  const quaternionRef = useRef<Quad | undefined>();
  const stoppedRef = useRef<boolean>(false);

  // console.log('FIBER DICE RENDER');

  useEffect(() => api.position.subscribe((pos) => (positionRef.current = pos)), [api, instanceIndex]);
  useEffect(() => api.quaternion.subscribe((quaternion) => (quaternionRef.current = quaternion)), [api, instanceIndex]);
  useEffect(() => api.velocity.subscribe((velocity) => (velocityRef.current = velocity)), [api, instanceIndex]);

  useFrame(({ clock }) => {
    //Example: https://codesandbox.io/s/cannon-collision-42sl6?file=/src/App.js:1617-1773
    if (onFrame) {
      onFrame(diceType, instanceIndex, clock, api, positionRef.current, velocityRef.current, quaternionRef.current);
    }

    if (onStarted || onStopped) {
      const stopped = !!velocityRef.current?.every((v) => v === 0);

      if (stopped === true && stoppedRef.current != stopped) {
        // Dice stopped
        if (onStopped && quaternionRef.current) {
          let upsideValue = getUpsideValue(diceInfo.description, diceInfo.geometry, quaternionRef.current);

          if (diceType === DiceType.d10 && upsideValue == 0) {
            upsideValue = 10;
          }

          onStopped(diceType, instanceIndex, upsideValue);
        }
      } else if (stopped === false && stoppedRef.current != stopped) {
        // Dice started
        onStarted && onStarted(diceType, instanceIndex);
      }

      stoppedRef.current = stopped;
    }
  });

  return <Instance ref={ref} />;
};

const useDiceBody = (
  args: ConvexPolyhedronArgs,
  mass: number,
  diceSounds?: DiceSounds,
): [RefObject<Mesh>, PublicApi] => {
  const collisionCallback = useCollisionSound(diceSounds);

  //NOTE: Be careful using PublicApi that is returned by useConvexPolyhedron in useEffect. useEffect should have same dependency list; otherwise old api object would be captured, api.subscribe stops working, etc.
  //NOTE: We do not want this object to be ever recreated. Otherwise it's position and speed will be reset to some defaults (object will jump).
  return useConvexPolyhedron<Mesh>(
    (): ConvexPolyhedronProps => {
      //NOTE: this is initial pos and velocities, they are used only when object is created. And object is never recreated, so. Initial only!
      const initPos: Triplet = [20 * Math.random() - 10, 10, 20 * Math.random() - 10];
      const initVelocities = randomizeVelocity([2 * Math.random() - 1, 2 * Math.random() - 1]);

      return {
        args,
        mass,
        linearDamping: 0.1,
        angularDamping: 0.1,
        collisionResponse: true,
        material: BodyMaterials.Dice,
        position: initPos,
        ...initVelocities,
        onCollide: collisionCallback,
        sleepSpeedLimit: 5, //If the speed (the norm of the velocity) is smaller than this value, the body is considered sleepy. @default 0.1
        sleepTimeLimit: 0.5, //If the body has been sleepy for this sleepTimeLimit seconds, it is considered sleeping. @default 1
      };
    },
    useRef,
    [args, collisionCallback, mass],
  );
};

const useCollisionSound = (diceSounds: DiceSounds | undefined) => {
  const lastSoundTimeRef = useRef<number>(0);
  const soundsRef = useRef<DiceSounds | undefined>(diceSounds);

  useEffect(() => {
    soundsRef.current = diceSounds;
  }, [diceSounds]);

  //NOTE: We want this callback to be stable. We do not want it to be recreated when diceSounds update.
  const stableCallback = useCallback(({ contact, body }: CollideEvent) => {
    if (!soundsRef.current) {
      return;
    }

    const hitFloor = body.name === BodyNames.Floor || body.name === BodyNames.Wall;
    const hitDice = body.name === BodyNames.Dice;

    const hitSomething = hitFloor || hitDice;
    const enoughVelocityForSound = Math.abs(contact.impactVelocity) > MIN_SOUND_VELOCITY;
    const enoughTimePassed = Date.now() - lastSoundTimeRef.current > SOUND_DELAY_MS;

    if (hitSomething && enoughVelocityForSound && enoughTimePassed) {
      const volume = MathUtils.clamp(contact.impactVelocity / 100, MIN_SOUND_VOLUME, MAX_SOUND_VOLUME);
      playRandomSound(hitFloor ? soundsRef.current.floorSounds : soundsRef.current.diceSounds, volume, hitFloor);

      lastSoundTimeRef.current = Date.now();
    }
  }, []);

  return stableCallback;
};

export const getUpsideValue = (desc: DiceDescription, geometry: BufferGeometry, quaternion: Quad): number => {
  const vector = new THREE.Vector3(0, desc.invertUpside ? -1 : 1);
  let closest_face: { start: number; count: number; materialIndex?: number | undefined } | undefined = undefined;
  let closest_angle = Math.PI * 2;

  const normalAttribute = geometry.getAttribute('normal') as THREE.BufferAttribute;
  const normals = normalAttribute.array;
  for (let i = 0; i < geometry.groups.length; ++i) {
    const face = geometry.groups[i];
    if (face.materialIndex === 0) continue;

    //Each group consists in 3 vertices of 3 elements (x, y, z) so the offset between faces in the Float32BufferAttribute is 9
    const startVertex = i * 9;
    const normal = new THREE.Vector3(normals[startVertex], normals[startVertex + 1], normals[startVertex + 2]);
    const angle = normal
      .clone()
      .applyQuaternion(new THREE.Quaternion(...quaternion))
      .angleTo(vector);

    if (angle < closest_angle) {
      closest_angle = angle;
      closest_face = face;
    }
  }

  if (!closest_face || !closest_face.materialIndex) {
    throw new Error('cannot find closest face');
  }

  return closest_face.materialIndex - 1;
};
