import { getRandomAbilities, getRemainingPoints } from '../../api/DnD/abilityScoreCalculator';
import { CharacterAction } from './reducer';
import { AppDispatch, AppState, AppThunkAction, CharacterInfo } from '../state';
import { createShareUrl } from '../../api/urlCharacterSerializer';
import { getRandomCharacterInfo } from '../../api/DnD/characterGenerator';
import { AbilityType } from '../../api/DnD/abilities';

const setCharacter =
  (info: Partial<CharacterInfo>): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState, { heroStorage }): void => {
    const { characterInfo } = getState().character;

    const newCharacter = { ...characterInfo, ...info };

    heroStorage.saveCharacterInfo(newCharacter);
    dispatch(CharacterAction.setCharacterInfo(newCharacter));
  };

const setAbility =
  (abilityType: AbilityType, value: number): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState, { heroStorage }): void => {
    const { abilities } = getState().character;

    const newAbilities = abilities.set(abilityType, value);
    const remainingAbilityPoints = getRemainingPoints(newAbilities);

    if (remainingAbilityPoints >= 0) {
      heroStorage.saveAbilities(newAbilities);
      dispatch(CharacterAction.setAbilities(newAbilities));
    }
  };

const setAbilities =
  (abilities: Immutable.Map<AbilityType, number>): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState, { heroStorage }): void => {
    const remainingAbilityPoints = getRemainingPoints(abilities);

    if (remainingAbilityPoints >= 0) {
      heroStorage.saveAbilities(abilities);
      dispatch(CharacterAction.setAbilities(abilities));
    } else {
      throw new Error('Incorrect ability scores. No remaining points left.');
    }
  };

const randomize =
  (): AppThunkAction =>
  (dispatch: AppDispatch, _getState, { heroStorage }): void => {
    const character = getRandomCharacterInfo();
    heroStorage.saveCharacterInfo(character);
    dispatch(CharacterAction.setCharacterInfo(character));

    const abilities = getRandomAbilities();

    heroStorage.saveAbilities(abilities);
    dispatch(CharacterAction.setAbilities(abilities));
  };

const copyShareCharacterUrl =
  (): AppThunkAction =>
  (dispatch, getState): void => {
    const { character } = getState();

    const copyCharUrl = createShareUrl(character, window);

    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(copyCharUrl);
    }
  };

export const CharacterThunks = {
  setCharacter,
  setAbility,
  randomize,
  copyShareCharacterUrl,
  setAbilities,
};
