import * as React from 'react';
import { getAbilityMod } from '../../api/DnD/abilityScoreCalculator';
import { toRaceName } from '../../api/DnD/races';
import { CharacterState } from '../../redux/state';
import { Button } from '../common/Button/Button';
import { Dialog } from '../common/Dialog/Dialog';
import { Icon } from '../common/Icon';
import styles from './ImportCharacterDialog.module.css';
import { getAvatarFace } from '../../avatars';

type Props = {
  characterToImport: CharacterState;
  showAvatar?: boolean;
  onImport: () => void;
  onDismiss: () => void;
};

export const ImportCharacterDialog: React.FC<Props> = ({ characterToImport, showAvatar, onImport, onDismiss }) => {
  const { characterInfo, abilities } = characterToImport;

  return (
    <Dialog onDismiss={onDismiss}>
      <div className={styles.content}>
        {showAvatar && <Icon url={getAvatarFace(characterInfo.avatarFileName)} width={96} height={96} />}
        <div className={styles.name}>{characterInfo.name}</div>
        <div>
          {toRaceName(characterInfo.race)}, {characterInfo.class}
        </div>
        <div className={styles.abilities}>
          {abilities.entrySeq().map(([type, value]) => {
            const mod = getAbilityMod(value);

            return (
              <div className={styles.ability} key={type}>
                <div>{type}</div>
                <div>{value}</div>
                <div className={styles.mod}>{mod >= 0 ? `+${mod}` : mod}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.info}>
        Do you want to override the current character by <span className={styles.charName}>{characterInfo.name}</span>?
      </div>
      <div className={styles.buttons}>
        <Button className={styles.cancelButton} onClick={onDismiss}>
          Cancel
        </Button>
        <Button className={styles.importButton} onClick={onImport}>
          Override
        </Button>
      </div>
    </Dialog>
  );
};
