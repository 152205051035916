import * as React from 'react';
import styles from './Checkbox.module.css';
import { Button } from '../Button/Button';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';

type Props = {
  label: string;
  checked?: boolean;
  onChange?: () => void;
};

export const Checkbox: React.FC<Props> = ({ label, checked, onChange }) => (
  <div className={styles.container} onClick={onChange}>
    <Button className={styles.checkbox}>{checked && <CheckIcon width={16} height={16} />}</Button>
    {label}
  </div>
);
