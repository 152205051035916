import { PlaneProps, Triplet, usePlane } from '@react-three/cannon';
import { MeshProps } from '@react-three/fiber';
import React, { useRef } from 'react';
import { BufferGeometry, Material, Mesh } from 'three';
import { BodyMaterials } from './r3fConstants';

type Props = MeshProps & {
  side: 'left' | 'far' | 'right' | 'near' | 'ceil' | 'floor';
  distanceFromCenter: number;
  children?: React.ReactNode;
  name?: string;
};

export const Wall: React.FC<Props> = (props): JSX.Element => {
  const [ref] = usePlane<Mesh<BufferGeometry, Material | Material[]>>(() => toPlaneProps(props), useRef, [props]);

  // NOTE: children will be passed as part of props
  return <mesh ref={ref} {...props} />;
};

const toPlaneProps = ({ side, distanceFromCenter = 0 }: Props): PlaneProps => {
  let position: Triplet = [0, 0, 0];
  let rotation: Triplet = [0, 0, 0];
  let material = BodyMaterials.Wall;

  switch (side) {
    case 'left':
      position = [distanceFromCenter, 0, 0];
      rotation = [0, Math.PI / 2, 0];
      break;
    case 'far':
      position = [0, 0, distanceFromCenter];
      rotation = [0, 0, 0]; // keep it as is!
      break;
    case 'right':
      position = [distanceFromCenter, 0, 0];
      rotation = [0, -Math.PI / 2, 0];
      break;
    case 'near':
      position = [0, 0, distanceFromCenter];
      rotation = [0, -Math.PI, 0];
      break;
    case 'ceil':
      position = [0, distanceFromCenter, 0];
      rotation = [Math.PI / 2, 0, 0];
      break;
    case 'floor':
      position = [0, distanceFromCenter, 0];
      rotation = [-Math.PI / 2, 0, 0];
      material = BodyMaterials.Floor;
      break;
  }

  return {
    type: 'Static',
    rotation,
    position,
    material,
  };
};
