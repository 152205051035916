import { Reducer } from 'redux';
import { createReducer, describeAction } from 'ts-describe-action';
import { SettingsStorage } from '../../api/settingsStorage';
import { deserializeCharacter } from '../../api/urlCharacterSerializer';
import { ActionTypes } from '../actionTypes';
import { CharacterState, RollSuggetion, SettingsState } from '../state';

const setShowAvatar = describeAction(
  'SET_SHOW_AVATAR',
  (prev: SettingsState, showAvatar: boolean): SettingsState => ({
    ...prev,
    showAvatar,
  }),
);

const setOrthographicCameraEnabled = describeAction(
  'SET_ORTHOGRAPHIC_CAMERA',
  (prev: SettingsState, orthographicCameraEnabled: boolean): SettingsState => ({
    ...prev,
    orthographicCameraEnabled,
  }),
);

const setAlterntaeArrow = describeAction(
  'SET_ALTERNTAE_ARROW',
  (prev: SettingsState, alterntaeArrow: boolean): SettingsState => ({
    ...prev,
    alterntaeArrow,
  }),
);

const setGameTag = describeAction(
  'SET_GAME_TAG',
  (prev: SettingsState, tag: string | undefined): SettingsState => ({
    ...prev,
    currentTag: tag,
  }),
);

const setCharacterToImport = describeAction(
  'SET_CHARACTER_TO_IMPORT',
  (prev: SettingsState, characterToImport: CharacterState): SettingsState => ({
    ...prev,
    characterToImport,
  }),
);

const clearCharacterToImport = describeAction(
  'CLEAR_CHARACTER_TO_IMPORT',
  (prev: SettingsState): SettingsState => ({
    ...prev,
    characterToImport: undefined,
  }),
);

const setRollSuggestions = describeAction(
  'SET_ROLL_SUGGESTIONS',
  (prev: SettingsState, rollSuggestions: RollSuggetion[]): SettingsState => ({
    ...prev,
    rollSuggestions,
  }),
);

export const SettingsAction = {
  setShowAvatar,
  setOrthographicCameraEnabled,
  setGameTag,
  setCharacterToImport,
  clearCharacterToImport,
  setRollSuggestions,
  setAlterntaeArrow,
};

export type SettingsActionType = ActionTypes<typeof SettingsAction>;

const getInitialState = (settingsStorage: SettingsStorage): SettingsState => {
  const characterToImport = window.location.search
    ? deserializeCharacter(new URLSearchParams(window.location.search))
    : undefined;

  const initState: SettingsState = {
    showAvatar: settingsStorage.getShowAvatar(),
    orthographicCameraEnabled: settingsStorage.getEnableOrthographicCamera(),
    rollSuggestions: settingsStorage.getRollSuggestions(),
    currentTag: settingsStorage.getGameTag(),
    characterToImport,
    alterntaeArrow: settingsStorage.getAlterntaeArrow(),
  };

  return initState;
};

export const createSettingsReducer = (settingsStorage: SettingsStorage): Reducer<SettingsState> =>
  createReducer(Object.values(SettingsAction), getInitialState(settingsStorage));
