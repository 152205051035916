import React from 'react';
import { toClassName } from '../../api/DnD/classes';
import { toRaceName } from '../../api/DnD/races';
import { useCharacterInfo } from '../../redux/character/selectors';
import { Button } from '../common/Button/Button';
import { Icon } from '../common/Icon';
import { getAvatarFace } from '../../avatars';

import styles from './CharacterButton.module.css';
import { Stack, StackDirection } from '../common/Stack';

type Props = {
  onClick?: () => void;
  showAvatar?: boolean;
};

export const CharacterButton: React.FC<Props> = ({ showAvatar, onClick }) => {
  const characterInfo = useCharacterInfo();

  return (
    <Button onClick={onClick} className={styles.character}>
      <Stack direction={StackDirection.Horizontal} gap={8}>
        {showAvatar && (
          <div className={styles.avatar}>
            <Icon url={getAvatarFace(characterInfo.avatarFileName)} width={48} height={48} />
          </div>
        )}
        <Stack direction={StackDirection.Vertical} alignItems="flex-start">
          <div className={styles.name}>{characterInfo.name}</div>
          <div className={styles.raceAndClass}>
            {toRaceName(characterInfo.race)}, {toClassName(characterInfo.class)}
          </div>
        </Stack>
      </Stack>
    </Button>
  );
};
