import React from 'react';
import { useAbilities } from '../../redux/character/selectors';
import { useRollInProgress, useRollModifier } from '../../redux/dices/selectors';
import { AbilityItem } from '../AbilityItem/AbilityItem';
import styles from './AbilityItems.module.css';

export const AbilityItems: React.FC = () => {
  const abilities = useAbilities();
  const rollInProgress = useRollInProgress();
  const rollModifier = useRollModifier();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {abilities.valueSeq().map((ability) => {
          const selected = ability.type === rollModifier;
          return (
            <AbilityItem
              key={ability.type}
              ability={ability}
              selected={selected}
              pulseWhenSelected={selected && rollInProgress}
            />
          );
        })}
      </div>
    </div>
  );
};
