import * as React from 'react';
import { DiceType } from '../../api/DnD/dices';
import { ReactComponent as D10 } from '../../assets/icons/dices/dice_d10.svg';
import { ReactComponent as D12 } from '../../assets/icons/dices/dice_d12.svg';
import { ReactComponent as D20 } from '../../assets/icons/dices/dice_d20.svg';
import { ReactComponent as D4 } from '../../assets/icons/dices/dice_d4.svg';
import { ReactComponent as D6 } from '../../assets/icons/dices/dice_d6.svg';
import { ReactComponent as D8 } from '../../assets/icons/dices/dice_d8.svg';
import { DiceAction } from '../../redux/dices/reducer';
import { useDicesToRoll } from '../../redux/dices/selectors';
import { useAppDispatch } from '../../redux/store';
import { CountSelector } from '../common/CountSelector/CountSelector';

import styles from './DiceSelector.module.css';

export const DiceSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const dicesToRoll = useDicesToRoll();

  const saveSelection = React.useCallback(
    (diceType: DiceType, value: number) => {
      const newSelection = value > 0 ? dicesToRoll.set(diceType, value) : dicesToRoll.remove(diceType);
      dispatch(DiceAction.setDicesToRoll(newSelection));
    },
    [dicesToRoll, dispatch],
  );

  return (
    <div className={styles.container}>
      {Object.values(DiceType).map((diceType) => {
        const value = dicesToRoll.get(diceType) || 0;

        return (
          <CountSelector
            key={diceType}
            text={diceType}
            value={value}
            minValue={0}
            maxValue={5}
            onValueChanged={(value) => saveSelection(diceType, value)}>
            <div className={styles.selectorContent} style={{ opacity: value === 0 ? 0.4 : 1 }}>
              <DiceIcon diceType={diceType} size={42} />
              <div className={styles.count}>{value}</div>
            </div>
          </CountSelector>
        );
      })}
    </div>
  );
};

type DiceIconParams = {
  diceType: DiceType;
  size: number;
  fill?: string | undefined;
};

const DiceIcon: React.FC<DiceIconParams> = ({ diceType, size, fill }) => {
  switch (diceType) {
    case DiceType.d4:
      return <D4 width={size} height={size} fill={fill} title={`Dice ${diceType}`} />;
    case DiceType.d6:
      return <D6 width={size} height={size} fill={fill} title={`Dice ${diceType}`} />;
    case DiceType.d8:
      return <D8 width={size} height={size} fill={fill} title={`Dice ${diceType}`} />;
    case DiceType.d10:
      return <D10 width={size} height={size} fill={fill} title={`Dice ${diceType}`} />;
    case DiceType.d12:
      return <D12 width={size} height={size} fill={fill} title={`Dice ${diceType}`} />;
    case DiceType.d20:
      return <D20 width={size} height={size} fill={fill} title={`Dice ${diceType}`} />;
  }
};
