import Immutable from 'immutable';
import { CharacterInfo } from '../redux/state';
import { AbilityType } from './DnD/abilities';

const ABILITIES_KEY = 'char_abilities';
const CHARACTER_KEY = 'char_info';

export type HeroStorage = {
  readAbilities: () => Immutable.Map<AbilityType, number> | null;
  saveAbilities: (abilities: Immutable.Map<AbilityType, number>) => void;

  readCharacterInfo: () => CharacterInfo | null;
  saveCharacterInfo: (character: CharacterInfo) => void;
};

export const createHeroStorage = (): HeroStorage => {
  const readAbilities = (): Immutable.Map<AbilityType, number> | null => {
    const jsonValue = localStorage.getItem(ABILITIES_KEY);

    if (jsonValue) {
      const abilities: [AbilityType, number][] = JSON.parse(jsonValue);
      const abilitiesMap = Immutable.Map(abilities);

      //NOTE: this is very dumb validation, that I have added after renaming values in AbilityType enum
      //TODO: do real validation!
      if (!abilitiesMap.get(AbilityType.STR)) {
        return null;
      }

      return abilitiesMap;
    }

    return null;
  };

  const saveAbilities = (abilities: Immutable.Map<AbilityType, number>): void => {
    localStorage.setItem(
      ABILITIES_KEY,
      JSON.stringify(
        abilities
          .entrySeq()
          .map(([type, value]) => [type, value])
          .toArray(),
      ),
    );
  };

  const readCharacterInfo = (): CharacterInfo | null => {
    const jsonValue = localStorage.getItem(CHARACTER_KEY);

    if (jsonValue) {
      const character: CharacterInfo = JSON.parse(jsonValue);
      return character;
    }

    return null;
  };

  const saveCharacterInfo = (character: CharacterInfo): void => {
    localStorage.setItem(CHARACTER_KEY, JSON.stringify(character));
  };

  return {
    readAbilities,
    saveAbilities,
    readCharacterInfo,
    saveCharacterInfo,
  };
};
