import React, { useEffect, useState } from 'react';

type Props = {
  delayMs: number;
  children: React.ReactNode;
};

export const Delay: React.FC<Props> = ({ delayMs, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, delayMs);
  }, [delayMs]);

  return show ? <>{children}</> : null;
};
