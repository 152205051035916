import React from 'react';
import { useTopPanelFromStack } from '../../redux/panels/selectors';
import { PanelsThunk } from '../../redux/panels/thunks';
import { useAppDispatch } from '../../redux/store';
import { CharacterButton } from '../CharacterButton/CharacterButton';
import { RollResultsNotification } from '../RollResultsNotification/RollResultsNotification';

import styles from './TopToolbar.module.css';

type Props = {
  showAvatar: boolean;
};

export const TopToolbar: React.FC<Props> = ({ showAvatar }) => {
  const dispatch = useAppDispatch();
  const leftTopPanel = useTopPanelFromStack('left');

  return (
    <div className={styles.container}>
      <div className={styles.hero}>
        <CharacterButton
          showAvatar={showAvatar}
          onClick={() => {
            if (!leftTopPanel) {
              dispatch(PanelsThunk.pushPanel('left', 'hero'));
            }
          }}
        />
      </div>
      <RollResultsNotification className={styles.notification} />
    </div>
  );
};
