import * as React from 'react';
import { Button } from '../Button/Button';
import { Icon } from '../Icon';
import { Badge } from '../Badge/Badge';

import styles from './RoundButton.module.css';

type Props = {
  style?: React.CSSProperties | undefined;
  className?: string;
  imageUrl: string;
  iconWidth?: number;
  iconHeight?: number;
  badgeIcon?: string;
  badgeVisible?: boolean;
  onClick: () => void;
};

export const RoundButton: React.FC<Props> = ({
  style,
  className,
  imageUrl,
  iconWidth = 64,
  iconHeight = 64,
  badgeVisible,
  badgeIcon,
  onClick,
}) => (
  <Button className={className ? `${styles.container} ${className}` : styles.container} style={style} onClick={onClick}>
    <Icon url={imageUrl} width={iconWidth} height={iconHeight} />
    {badgeVisible && <Badge iconUrl={badgeIcon} className={styles.badge} />}
  </Button>
);
