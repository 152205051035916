import React from 'react';
import { useContactMaterial } from '@react-three/cannon';
import { BodyMaterials } from './r3fConstants';

export const ContactMaterials: React.FC = (): JSX.Element => {
  useContactMaterial(BodyMaterials.Floor, BodyMaterials.Dice, {
    friction: 0.01,
    restitution: 0.5,
  });

  useContactMaterial(BodyMaterials.Wall, BodyMaterials.Dice, {
    friction: 0,
    restitution: 1.0,
  });

  useContactMaterial(BodyMaterials.Dice, BodyMaterials.Dice, {
    friction: 0,
    restitution: 0.5,
  });

  return <></>;
};
