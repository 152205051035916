import React, { useEffect } from 'react';
import D20ButtonIcon from '../../assets/icons/d20button.svg';
import HeroIcon from '../../assets/icons/hero.svg';
import MenuIcon from '../../assets/icons/menu.svg';
import DoubleUpIcon from '../../assets/icons/double-up.svg';
import { ROUND_BUTTON_SIZE } from '../../constants';
import { CardThunk } from '../../redux/cards/thunks';
import { useRemainingAbilityPoints } from '../../redux/character/selectors';
import { PanelsThunk } from '../../redux/panels/thunks';
import { useCharacterToImport, useShowAvatar } from '../../redux/settings/selectors';
import { SettingsThunk } from '../../redux/settings/thunks';
import { useAppDispatch } from '../../redux/store';
import { AbilityItems } from '../AbilityItems/AbilityItems';
import { InventoryPreview } from '../CharacterPanel/InventoryPreview';
import { DiceBoard } from '../DiceBoard/DiceBoard';
import { ImportCharacterDialog } from '../ImportCharacterDialog/ImportCharacterDialog';
import { MainMenu } from '../MainMenu/MainMenu';
import { Panels } from '../Panels';
import { RoundButton } from '../common/RoundButton/RoundButton';
import { Stack, StackDirection } from '../common/Stack';
import { BottomToolbar } from './BottomToolbar';
import { TopToolbar } from './TopToolbar';

import styles from './MainScreen.module.css';

export const MainScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const showAvatar = useShowAvatar();
  const characterToImport = useCharacterToImport();
  const remainingPoints = useRemainingAbilityPoints();
  //TODO: probably MainMenu should be handled same as panel? Do we have toplevel panels there?
  const [menuVisible, setMenuVisible] = React.useState(false);

  useEffect(() => {
    dispatch(CardThunk.loadCards());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <TopToolbar showAvatar={showAvatar} />

      <DiceBoard>
        <AbilityItems />
      </DiceBoard>

      <Panels />

      <BottomToolbar>
        {/* Hero Button */}
        <RoundButton
          imageUrl={HeroIcon}
          onClick={() => dispatch(PanelsThunk.pushPanel('left', 'hero'))}
          iconWidth={ROUND_BUTTON_SIZE}
          iconHeight={ROUND_BUTTON_SIZE}
          badgeVisible={remainingPoints > 0}
          badgeIcon={DoubleUpIcon}
        />

        {/* Inventory */}
        <InventoryPreview mode="row" background="dark" />

        <Stack direction={StackDirection.Vertical} gap={8}>
          {/* Menu Button */}
          <RoundButton
            imageUrl={MenuIcon}
            iconWidth={ROUND_BUTTON_SIZE}
            iconHeight={ROUND_BUTTON_SIZE}
            onClick={() => setMenuVisible(true)}
          />

          {/* Dice Button */}
          <RoundButton
            imageUrl={D20ButtonIcon}
            onClick={() => dispatch(PanelsThunk.pushPanel('right', 'dices'))}
            iconWidth={ROUND_BUTTON_SIZE}
            iconHeight={ROUND_BUTTON_SIZE}
          />
        </Stack>
      </BottomToolbar>

      {/* Import Character Dialog */}
      {characterToImport && (
        <ImportCharacterDialog
          showAvatar={showAvatar}
          characterToImport={characterToImport}
          onImport={() => dispatch(SettingsThunk.importCharacter())}
          onDismiss={() => dispatch(SettingsThunk.clearCharacterToImport())}
        />
      )}

      {/* Menu */}
      {menuVisible && <MainMenu onDismiss={() => setMenuVisible(false)} />}
    </div>
  );
};
