import { useEffect, useRef, useState } from 'react';
import { Store } from 'redux';
import { AppState, AppActions } from '../redux/state';
import { createReduxStore } from '../redux/store';

export const useNewStore = () => {
  /**
   * NOTE: we need this because in debug in StrictMode react calls all effects twice.
   * To help prevent bugs, React does a lot of magic in StrictMode.
   * See more info here https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
   */
  const storeRef = useRef<Store<AppState, AppActions> | undefined>(undefined);
  const [store, setStore] = useState<Store<AppState, AppActions> | undefined>(undefined);

  /**
   * TODO: this effect will be called only once, but maybe it would be better to extract store outside
   * of the component and have it as a global variable?
   */
  useEffect(() => {
    if (!storeRef.current) {
      //NOTE: this will be useful when we will have async store initialization
      storeRef.current = createReduxStore();
      setStore(storeRef.current);
    }
  }, []);

  return store;
};
