import * as React from 'react';
import { Icon } from '../Icon';

import styles from './Badge.module.css';

type Props = {
  iconUrl?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
};

export type BadgeIcon = 'star';

export const Badge: React.FC<Props> = ({ iconUrl, style, className }) => (
  <div className={className ? `${styles.badge} ${className}` : styles.badge} style={style}>
    {iconUrl && <Icon url={iconUrl} width={18} height={18} />}
  </div>
);
