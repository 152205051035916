import * as React from 'react';
import { BASIC_RULES_URL } from '../../constants';
import { AnalyticsThunk } from '../../redux/analytics/thunks';
import { useAppDispatch } from '../../redux/store';
import { LinkButton } from '../common/Button/Button';
import { Panel, PanelPlacement } from '../common/Panel/Panel';

import styles from './HelpPanel.module.css';

type Props = {
  onDismiss: () => void;
  panelPlacement?: PanelPlacement;
};

export const HelpPanel: React.FC<Props> = ({ panelPlacement, onDismiss }) => {
  const dispatch = useAppDispatch();

  return (
    <Panel
      key="helpPanel"
      panelStyle="floating"
      panelPlacement={panelPlacement}
      showOverlay={panelPlacement === 'bottom'}
      closePlacement={'bottomLeft'}
      backInsteadClose={true}
      toolbarComponent={
        <LinkButton
          url={BASIC_RULES_URL}
          className={styles.toolButton}
          tooltip="Open basic rules"
          onNavigated={() => dispatch(AnalyticsThunk.helpClicked())}>
          Open basic rules
        </LinkButton>
      }
      onDismiss={onDismiss}>
      <div className={styles.info}>
        <div style={{ fontSize: 'large' }}>Ability Score Point Cost</div>
        <div className={styles.pointsInfo}>
          <div>1 point to increase ability value up to 13.</div>
          <div>2 points to increase ability value above 13.</div>
          <div>Max ability value 15.</div>
        </div>
      </div>
    </Panel>
  );
};
