export const isDebug = () => {
  const env = process.env;

  if (!env.NODE_ENV && !env.DEBUG) return false;
  if (env.DEBUG) return true;
  if (env.NODE_ENV) {
    const prefix = env.NODE_ENV.substring(0, 3).toUpperCase();
    return prefix === 'DEV' || prefix === 'DEB';
  }
  return false;
};

export const delayAsync = async (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

export const openUrl = (url: string): void => window.open(url, '_blank')?.focus();

export const toNumWithSign = (value: number, spacesAround?: boolean): string => {
  const sign = value >= 0 ? '+' : '-';
  const num = Math.abs(value).toString();

  return spacesAround ? ` ${sign} ${num}` : `${sign}${num}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}
