import * as Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AbilityType } from '../../api/DnD/abilities';
import { DiceType } from '../../api/DnD/dices';
import { AppState, CalculatedRollResults, RollResults, RollType } from '../state';
import { diceTypeToNumber } from '../../utils/diceUtils';
import { assertUnreachable } from '../../utils';

const selectRollModifier = (state: AppState): AbilityType | undefined => state.dices.rollModifier;

const selectDicesToRoll = (state: AppState) => state.dices.dicesToRoll;

const selectRollResults = (state: AppState): RollResults | undefined => state.dices.rollResults;

const selectRollInProgress = (state: AppState): boolean => state.dices.rollInProgress;

const selectResultModifier = (state: AppState): number | undefined => state.dices.resultModifier;

const selectRollType = (state: AppState): RollType => state.dices.rollType;

const selectCalculatedRollResults = createSelector(
  selectRollResults,
  (rollResults: RollResults | undefined): CalculatedRollResults | undefined => {
    if (!rollResults) {
      return undefined;
    }

    const { results, withAbility, withResultModifier, withRollType } = rollResults;

    let finalResult = 0;

    switch (withRollType) {
      case 'normal':
        finalResult = results.reduce((prev, curr) => curr.value + prev, 0);
        break;
      case 'advantage':
        finalResult = results.maxBy((x) => x.value)?.value || 0;
        break;
      case 'disadvantage':
        finalResult = results.minBy((x) => x.value)?.value || 0;
        break;
      default:
        assertUnreachable(withRollType);
    }

    finalResult += withAbility?.modifier || 0;
    finalResult += withResultModifier || 0;

    return {
      ...rollResults,
      results: results.sortBy((x) => x.value).sortBy((x) => diceTypeToNumber(x.diceType)),
      finalResult,
    };
  },
);

export const useRollModifier = (): AbilityType | undefined => useSelector(selectRollModifier);

export const useDicesToRoll = (): Immutable.Map<DiceType, number> => useSelector(selectDicesToRoll);

export const useRollResults = (): RollResults | undefined => useSelector(selectRollResults);

export const useCalculatedRollResults = (): CalculatedRollResults | undefined =>
  useSelector(selectCalculatedRollResults);

export const useRollInProgress = (): boolean => useSelector(selectRollInProgress);

export const useResultModifier = (): number | undefined => useSelector(selectResultModifier);

export const useRollType = (): RollType => useSelector(selectRollType);
