import Immutable from 'immutable';
import { AbilityType } from '../../api/DnD/abilities';
import { getAbilityMod } from '../../api/DnD/abilityScoreCalculator';
import { getRaceModificator } from '../../api/DnD/races';
import { toDicesArray } from '../../utils/diceUtils';
import { AnalyticsThunk } from '../analytics/thunks';
import { SettingsThunk } from '../settings/thunks';
import { AppDispatch, AppState, AppThunkAction, RollResult, RollSource } from '../state';
import { DiceAction } from './reducer';

//NOTE: this is called only for rolls initiated by user, for 'init' rolls this action is not called. That means that rollInProgress is false for initial rolls.
const startDiceRoll =
  (): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState): void => {
    const { dicesToRoll, resetAbilityOnNextRoll, resultModifier = 0, rollType } = getState().dices;

    if (resetAbilityOnNextRoll === true || resetAbilityOnNextRoll === undefined) {
      dispatch(DiceAction.setRollModifier(undefined));
    }

    dispatch(DiceAction.setRollResults(undefined));
    dispatch(DiceAction.setRollInProgress(true));
    dispatch(SettingsThunk.addRollSuggestion({ dices: toDicesArray(dicesToRoll), resultModifier, rollType }));
  };

const completeDiceRoll =
  (rollResults: RollResult[] | undefined, source: RollSource): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState): void => {
    // const { resetAbilityOnNextRoll } = getState().dices;
    // if (resetAbilityOnNextRoll === true || resetAbilityOnNextRoll === undefined) {
    //   dispatch(DiceAction.setRollModifier(undefined));
    // }

    const { dices, character } = getState();
    const { rollModifier, resultModifier, rollType } = dices;
    const { abilities, characterInfo } = character;

    if (source !== 'init') {
      //NOTE: we do not want to track initial(automatic) rolls
      dispatch(AnalyticsThunk.diceRolled(source));
    }

    const currentAbilityValue = rollModifier && abilities.get(rollModifier);
    const raceMod = rollModifier ? getRaceModificator(characterInfo.race, rollModifier) : 0;

    dispatch(DiceAction.setResetAbilityOnNextRoll(true));
    dispatch(
      DiceAction.setRollResults({
        results: Immutable.List(rollResults),
        withAbility: currentAbilityValue
          ? {
              type: rollModifier,
              modifier: getAbilityMod(currentAbilityValue + raceMod), //TODO: switch to calcAbilityModifier
            }
          : undefined,
        withResultModifier: resultModifier,
        withRollType: rollType,
      }),
    );
    dispatch(DiceAction.setRollInProgress(false));
  };

const setRollModifier =
  (rollModifier: AbilityType | undefined): AppThunkAction =>
  (dispatch: AppDispatch): void => {
    if (rollModifier !== undefined) {
      dispatch(DiceAction.setResetAbilityOnNextRoll(false));
    }
    dispatch(DiceAction.setRollModifier(rollModifier));
  };

export const DiceThunk = {
  setRollModifier,
  startDiceRoll,
  completeDiceRoll,
};
