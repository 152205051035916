import * as React from 'react';

type Props = {
  themeColor: string;
};

export const SiteTheme: React.FC<Props> = ({ themeColor }) => {
  React.useEffect(() => {
    const oldColor = document.querySelector('meta[name="theme-color"]')?.getAttribute('content');

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', themeColor);

    return () => {
      if (oldColor) {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', oldColor);
      }
    };
  }, [themeColor]);

  return null;
};
