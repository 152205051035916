import { useState, useEffect } from 'react';
import { createResizeObserver } from '../utils/createResizeObserver';

export type WindowSize = {
  width: number;
  height: number;
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const resizeObserver = createResizeObserver((width, height) => setWindowDimensions({ width, height }));
    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, []);

  return windowDimensions;
};
