import * as React from 'react';
import { CardInfo } from '../../redux/state';
import { Icon } from '../common/Icon';
import { Stack, StackDirection } from '../common/Stack';

import styles from './Card.module.css';

type Props = {
  card: CardInfo;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  containerClassName?: string | undefined;
  contentClassName?: string | undefined;
};

export const Card: React.FC<Props> = ({ card, containerClassName, contentClassName, onClick }) => {
  return (
    <div className={`${styles.container} ${containerClassName}`} onClick={onClick}>
      <Stack
        direction={StackDirection.Vertical}
        alignItems="center"
        gap={8}
        className={`${styles.content} ${contentClassName}`}>
        <Icon url={card.image || 'images/inventory/scroll.png'} width={96} height={96} />
        <div className={styles.title}>{card.title}</div>
        <div className={styles.description}>{card.description}</div>
      </Stack>
    </div>
  );
};
