import * as React from 'react';
import { ReactComponent as LeftIcon } from '../../../assets/icons/left.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/right.svg';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import { Button } from '../Button/Button';
import { Panel } from '../Panel/Panel';

import styles from './ItemSelector.module.css';

type Props<T> = {
  items: T[];
  currentItem: T;
  onItemChanged: (newItem: T) => void;
  renderItem: (item: T, inSelector?: boolean) => React.ReactElement;
  selectorTitle?: string;
  displayAs?: 'list' | 'grid';
  fullWidth?: boolean;
  panelPlacement?: 'left' | 'right';
};

export function ItemSelector<T>({
  items,
  currentItem,
  selectorTitle,
  displayAs = 'list',
  fullWidth,
  panelPlacement = 'left',
  renderItem,
  onItemChanged,
}: Props<T>) {
  const currentIndex = React.useMemo(() => items.findIndex((img) => img === currentItem), [currentItem, items]);
  const [showSelector, setShowSelector] = React.useState(false);
  const scrollToElement = useScrollToElement();

  const selectNext = () => {
    if (items.length === 0) {
      return;
    }

    let index = currentIndex + 1;

    if (index >= items.length) {
      index = 0;
    }

    onItemChanged(items[index]);
  };

  const selectPrev = () => {
    if (items.length === 0) {
      return;
    }

    let index = currentIndex - 1;

    if (index < 0) {
      index = items.length - 1;
    }

    onItemChanged(items[index]);
  };

  return (
    <>
      <div className={styles.container}>
        <Button onClick={selectPrev}>
          <LeftIcon className={styles.switcher} />
        </Button>
        <Button className={fullWidth ? styles.fullWidthItem : undefined} onClick={() => setShowSelector(true)}>
          {renderItem(currentItem, false)}
        </Button>
        <Button onClick={selectNext}>
          <RightIcon className={styles.switcher} />
        </Button>
      </div>
      {showSelector && (
        <Panel
          showOverlay={true}
          panelStyle="cover"
          panelPlacement={panelPlacement}
          closePlacement={panelPlacement == 'left' ? 'bottomLeft' : 'bottomRight'}
          onDismiss={() => setShowSelector(false)}>
          {selectorTitle && <div className={styles.title}>{selectorTitle}</div>}

          <div className={styles.itemContainer}>
            <div className={displayAs === 'grid' ? styles.grid : styles.list}>
              {items.map((item, index) => (
                <div
                  className={styles.item}
                  key={index}
                  ref={(ref) => {
                    if (item === currentItem && ref) {
                      scrollToElement(ref);
                    }
                  }}>
                  <Button
                    key={index}
                    className={item === currentItem ? styles.selected : undefined}
                    onClick={() => {
                      onItemChanged(item);
                      setShowSelector(false);
                    }}>
                    {renderItem(item, true)}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </Panel>
      )}
    </>
  );
}
