import React from 'react';

import { Physics, Triplet } from '@react-three/cannon';
import { OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { DiceScene } from './DiceScene';
import { DragInfo } from './diceTypes';
import { useOrthographicCamera } from '../../redux/settings/selectors';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const GRAVITY: Triplet = [0, -9.82 * 20, 0];

const CAMERA_FOV = 23;
const CAMERA_ZOOM = 27;
const CAMERA_NEAR = 0.1;
const CAMERA_FAR = 1000;
const CAMERA_DISTANCE = 100;

type FiberDiceCanvasProps = {
  eventSource?: React.MutableRefObject<HTMLElement>;
  dragInfo: DragInfo | undefined;
  onDicesRolled: () => void;
};

export const FiberDiceCanvas: React.FC<FiberDiceCanvasProps> = ({ eventSource, dragInfo, onDicesRolled }) => {
  const orthographicCameraEnabled = useOrthographicCamera();
  const { height } = useWindowDimensions();

  return (
    <Canvas eventSource={eventSource} style={{ pointerEvents: 'none' }} shadows={true}>
      {!orthographicCameraEnabled && (
        <PerspectiveCamera
          makeDefault={true}
          fov={(CAMERA_FOV * height) / 1080}
          near={CAMERA_NEAR}
          far={CAMERA_FAR}
          position={[0, CAMERA_DISTANCE, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      )}
      {orthographicCameraEnabled && (
        <OrthographicCamera
          makeDefault={true}
          zoom={CAMERA_ZOOM}
          near={CAMERA_NEAR}
          far={CAMERA_FAR}
          position={[0, CAMERA_DISTANCE, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      )}
      <Physics gravity={GRAVITY} allowSleep={true}>
        {/* <Debug color="green" scale={1.05}> */}
        {/* <Stats /> */}
        <DiceScene dragInfo={dragInfo} onDicesRolled={onDicesRolled} />
        {/*when thurned on, sometimes causes sound error, for some weird position issue */}
        {/* <OrbitControls /> */}
        {/* </Debug> */}
      </Physics>
    </Canvas>
  );
};
