import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import artLogoLarge from '../assets/images/artLogoLarge.png';
import { useNewStore } from '../hooks/useNewStore';
import { AppActions, AppState } from '../redux/state';
import { Icon } from './common/Icon';
import { SiteTheme } from './common/SiteTheme';

const splashStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  backgroundColor: 'var(--main-dark)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

const titleStyle: React.CSSProperties = {
  color: 'white',
  fontSize: 'xx-large',
  fontFamily: 'var(--font-dnd)',
  letterSpacing: '1px',
  marginBottom: '20px',
};

export const ReduxApp: React.FC<{ children?: ReactNode | undefined }> = ({ children }) => {
  const store = useNewStore();
  return store ? getLoadedApp(store, children) : getSplashScreen();
};

const getSplashScreen = () => (
  <>
    <SiteTheme themeColor="#2d3047" />
    <div style={splashStyle}>
      <Icon url={artLogoLarge} width={280} height={280} />
      <div style={titleStyle}>d20gate</div>
    </div>
  </>
);

const getLoadedApp = (store: Store<AppState, AppActions>, children?: ReactNode | undefined) => (
  <>
    <SiteTheme themeColor="#b59472" />
    {/* <AvatarPreloader /> */}
    {/* <ItemsPreloader /> */}
    <Provider store={store}>{children}</Provider>
  </>
);
