import Immutable from 'immutable';
import { DiceType } from '../api/DnD/dices';
import { notEmpty } from './arrayUtils';

export const toDicesString = (value: Immutable.Map<DiceType, number>, separator = ','): string =>
  value.entrySeq().map(toDiceWithCount).join(separator);

export const toDiceWithCount = ([type, count]: [DiceType, number]): string => `${count}${type}`;

const parseDiceWithCount = (value: string): [DiceType, number] | undefined => {
  const regex = /^(?<count>\d+)(?<dice>d\d+)$/gim;
  const match = regex.exec(value);

  if (match?.groups) {
    const count = Number.parseInt(match.groups['count']);
    if (Number.isNaN(count)) {
      return undefined;
    }

    const diceType = DiceType[match.groups['dice'] as keyof typeof DiceType];
    if (!Object.values(DiceType).includes(diceType)) {
      return undefined;
    }

    return [diceType, count];
  }

  return undefined;
};

export const toDicesArray = (value: Immutable.Map<DiceType, number>): string[] =>
  value.entrySeq().map(toDiceWithCount).toArray();

export const fromDicesArray = (value: string[]): Immutable.Map<DiceType, number> | undefined => {
  const entries = value.map(parseDiceWithCount).filter(notEmpty);

  return entries.length > 0 ? Immutable.Map(entries) : undefined;
};

export const fromDicesString = (value: string, separator = ','): Immutable.Map<DiceType, number> | undefined => {
  const entries = value.split(separator).map(parseDiceWithCount).filter(notEmpty);

  return entries.length > 0 ? Immutable.Map(entries) : undefined;
};

export const diceTypeToNumber = (diceType: DiceType): number => {
  const diceString = diceType.toString().substring(1); // dXX => XX
  return Number.parseInt(diceString);
};
