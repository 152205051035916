import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState, CharacterState, RollSuggetion, SettingsState } from '../state';

export const selectCurrentTag = createSelector(
  (state: AppState): SettingsState => state.settings,
  (settings) => settings.currentTag,
);

export const useShowAvatar = (): boolean => useSelector((state: AppState) => state.settings.showAvatar);

export const useCharacterToImport = (): CharacterState | undefined =>
  useSelector((state: AppState) => state.settings.characterToImport);

export const useRollSuggestions = (): RollSuggetion[] | undefined =>
  useSelector((state: AppState) => state.settings.rollSuggestions);

export const useCurrentTag = (): string | undefined => useSelector(selectCurrentTag);

export const useOrthographicCamera = (): boolean =>
  useSelector((state: AppState) => state.settings.orthographicCameraEnabled);

export const useAlterntaeArrow = (): boolean =>
  useSelector((state: AppState): boolean => state.settings.alterntaeArrow);
