import { assertUnreachable } from '../../utils';
import { toDicesString } from '../../utils/diceUtils';
import { selectRemainingAbilityPoints } from '../character/selectors';
import { PanelType } from '../panels/types';
import { AppDispatch, AppState, AppThunkAction, MutableThunkState, RollSource } from '../state';

enum TrackedEvent {
  CharacterPanelOpen = 'charpanel_open',
  CharacterPanelClosed = 'charpanel_closed',
  DicePanelOpen = 'dicepanel_open',
  DicePanelClosed = 'dicepanel_closed',
  DiceRolled = 'dice_rolled',
  CharacterRandomizeClicked = 'charrandomize_clicked',
  NameRandomizeClicked = 'charnamerandomize_clicked',
  TwitterClicked = 'twitter_clicked',
  FeedbackClicked = 'feedback_clicked',
  HelpClicked = 'help_clicked',
  ShareCharacterClicked = 'sharecharacter_clicked',
  InventoryPanelOpen = 'inventoryspanel_open',
  InventoryPanelClosed = 'inventorypanel_closed',
}

const trackEmpty =
  (event: TrackedEvent): AppThunkAction =>
  (_dispatch: AppDispatch, _getState: () => AppState, { analytics }: MutableThunkState) => {
    analytics.track(event);
  };

const characterPanelOpen = (): AppThunkAction => trackEmpty(TrackedEvent.CharacterPanelOpen);

const characterPanelClosed =
  (): AppThunkAction =>
  (_dispatch: AppDispatch, getState: () => AppState, { analytics }: MutableThunkState) => {
    const state = getState();
    const { characterInfo, abilities } = state.character;
    const remainingPoints = selectRemainingAbilityPoints(state);

    analytics.track(TrackedEvent.CharacterPanelClosed, {
      race: characterInfo.race,
      class: characterInfo.class,
      abilities: abilities
        .entrySeq()
        .map(([abilityType, baseValue]) => `${abilityType.slice(0, 3).toLowerCase()}${baseValue}`)
        .join(','),
      remainingPoints,
    });
  };

const dicePanelOpen = (): AppThunkAction => trackEmpty(TrackedEvent.DicePanelOpen);

const dicePanelClosed =
  (): AppThunkAction =>
  (_dispatch: AppDispatch, getState: () => AppState, { analytics }: MutableThunkState) => {
    const { dices } = getState();

    analytics.track(TrackedEvent.DicePanelClosed, {
      diceset: toDicesString(dices.dicesToRoll),
    });
  };

const diceRolled =
  (rollSource: RollSource): AppThunkAction =>
  (_dispatch: AppDispatch, getState: () => AppState, { analytics }: MutableThunkState) => {
    const { dices } = getState();
    const { rollModifier, dicesToRoll } = dices;

    analytics.track(TrackedEvent.DiceRolled, {
      action: rollSource,
      dice: toDicesString(dicesToRoll),
      source: rollModifier ?? 'empty',
    });
  };

const characterRandomizeClicked = (): AppThunkAction => trackEmpty(TrackedEvent.CharacterRandomizeClicked);
const nameRandomizeClicked = (): AppThunkAction => trackEmpty(TrackedEvent.NameRandomizeClicked);
const twitterClicked = (): AppThunkAction => trackEmpty(TrackedEvent.TwitterClicked);
const feedbackClicked = (): AppThunkAction => trackEmpty(TrackedEvent.FeedbackClicked);
const helpClicked = (): AppThunkAction => trackEmpty(TrackedEvent.HelpClicked);
const shareCharacterClicked = (): AppThunkAction => trackEmpty(TrackedEvent.ShareCharacterClicked);

const panelOpen =
  (panelType: PanelType): AppThunkAction =>
  (dispatch: AppDispatch) => {
    switch (panelType) {
      case 'hero':
        dispatch(characterPanelOpen());
        break;
      case 'dices':
        dispatch(dicePanelOpen());
        break;
      case 'inventory':
        dispatch(trackEmpty(TrackedEvent.InventoryPanelOpen));
        break;
      case 'help':
        //do nothing
        break;
      default:
        assertUnreachable(panelType);
    }
  };

const panelClosed =
  (panelType: PanelType): AppThunkAction =>
  (dispatch: AppDispatch) => {
    switch (panelType) {
      case 'hero':
        dispatch(characterPanelClosed());
        break;
      case 'dices':
        dispatch(dicePanelClosed());
        break;
      case 'inventory':
        dispatch(trackEmpty(TrackedEvent.InventoryPanelClosed));
        break;
      case 'help':
        //do nothing
        break;
      default:
        assertUnreachable(panelType);
    }
  };

export const AnalyticsThunk = {
  characterPanelOpen, //TODO: remove
  characterPanelClosed, //TODO: remove
  dicePanelOpen, //TODO: remove
  dicePanelClosed, //TODO: remove
  diceRolled,
  characterRandomizeClicked,
  nameRandomizeClicked,
  twitterClicked,
  feedbackClicked,
  helpClicked,
  shareCharacterClicked,
  panelOpen,
  panelClosed,
};
