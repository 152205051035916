import { useSelector } from 'react-redux';
import { AppState } from '../state';
import { PanelStack, PanelType, PanelsState } from './types';

export const selectPanelsState = (state: AppState): PanelsState => state.panels;
export const selectPanelStackByType = (state: AppState, stackType: PanelStack): Immutable.List<PanelType> | undefined =>
  state.panels.stacks.get(stackType);

export const selectTopPanelFromStack = (state: AppState, stackType: PanelStack): PanelType | undefined =>
  state.panels.stacks.get(stackType)?.last();

/** TODO: надо понять как правильно сделать, сейчас получается,
 * что все панели будут перерисовываться при каждом изменении стейта.
 * При этом сделать селектор с правильной мемоизацией тоже не получится,
 * т.к. будет мемоизироваться только последний тип стека, а нам при рендере надо доставать все PanelStack каждый раз.
 * Можно подумать про selectorFactory https://redux.js.org/usage/deriving-data-selectors#selector-factories
 */

export const usePanels = (): PanelsState => useSelector((state: AppState) => state.panels);
export const usePanelStackByType = (stackType: PanelStack) =>
  useSelector((state: AppState) => selectPanelStackByType(state, stackType));
export const useTopPanelFromStack = (stackType: PanelStack) =>
  useSelector((state: AppState) => selectTopPanelFromStack(state, stackType));
