import { useCallback } from 'react';

export const useScrollToElement = (behavior?: ScrollBehavior | undefined): ((element: HTMLDivElement) => void) =>
  useCallback(
    (element: HTMLDivElement | undefined) => {
      /* NOTE: for some reason without timeout scrollIntoView won't work for items that
       * should be scrolled to on the first render.
       * For example in ItemSelector we select item immediately when it renders first time.
       *
       * Other possible solution is to use useState and useEffect, but I think current one is better.
       */
      setTimeout(() => {
        element?.scrollIntoView({ block: 'center', behavior });
      }, 10);
    },
    [behavior],
  );
