import Immutable from 'immutable';
import { Reducer } from 'redux';
import { createReducer, describeAction } from 'ts-describe-action';
import { ActionTypes } from '../actionTypes';
import { CardInfo, CardsState } from '../state';

const initCards = describeAction(
  'INIT_CARDS',
  (prev: CardsState, cards: Immutable.List<CardInfo>): CardsState => ({
    ...prev,
    cards,
  }),
);

const setSelectedCard = describeAction(
  'SET_SELECTED_CARD',
  (prev: CardsState, selectedCard: CardInfo | undefined): CardsState => ({
    ...prev,
    selectedCard: selectedCard && prev.cards.contains(selectedCard) ? selectedCard : undefined,
  }),
);

const setTypeFilter = describeAction(
  'SET_TYPE_FILTER',
  (prev: CardsState, itemTypeFilter: string | undefined): CardsState => ({
    ...prev,
    itemTypeFilter: prev.cards.some((card) => card.type === itemTypeFilter) ? itemTypeFilter : undefined,
  }),
);

export const CardAction = {
  initCards,
  setSelectedCard,
  setTypeFilter,
};

export type CardActionType = ActionTypes<typeof CardAction>;

export const createCardsReducer = (): Reducer<CardsState> =>
  createReducer(Object.values(CardAction), {
    cards: Immutable.List<CardInfo>(),
    selectedCard: undefined,
  });
