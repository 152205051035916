import Immutable from 'immutable';
import { CharacterState } from '../redux/state';

export const deserializeCharacter = (params: URLSearchParams): CharacterState | undefined => {
  const data = Object.fromEntries(params.entries());
  const charJson = JSON.parse(data['character']);
  const { abilities, characterInfo } = charJson;

  if (abilities && characterInfo) {
    const result: CharacterState = {
      abilities: Immutable.Map(abilities),
      characterInfo,
    };

    return result;
  }

  return undefined;
};

export const serializeCharacter = (character: CharacterState): URLSearchParams => {
  const params = new URLSearchParams();
  params.append('character', JSON.stringify(character));
  return params;
};

export const createShareUrl = (character: CharacterState, window: Window & typeof globalThis): string => {
  const params: URLSearchParams = serializeCharacter(character);
  const copyCharUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
  return copyCharUrl;
};
