import * as React from 'react';
import { Formula, FormulaTerm } from '../../redux/character/selectors';
import { Icon } from '../common/Icon';
import { Stack, StackDirection } from '../common/Stack';
import styles from './IconFormula.module.css';

type Props = {
  title: string;
  iconSource: string;
  formula: Formula;
};

export const IconFormula: React.FC<Props> = ({ title, iconSource, formula }) => {
  return (
    <Stack direction={StackDirection.Vertical} alignItems="center">
      {/* Title */}
      <div className={styles.title}>{title}</div>
      {/* Icon and Value */}
      <Stack direction={StackDirection.Horizontal} gap={8} alignItems="center">
        <Icon url={iconSource} width={28} height={28} />
        <span className={styles.result}>{formula.result}</span>
      </Stack>
      {/* Formula */}
      <Stack
        direction={StackDirection.Horizontal}
        justifyContent="center"
        alignItems="center"
        className={styles.term}
        gap={2}>
        {getFormulaElements(formula)}
      </Stack>
    </Stack>
  );
};

const toFormulaElement = (term: FormulaTerm, index: number): JSX.Element | null => {
  switch (term.source) {
    case 'initial':
      return (
        <span key={index} className={styles.baseColor}>
          {term.value}
        </span>
      );
    case 'ability':
      return (
        <span key={index} className={styles.abilityColor}>
          <span className={styles.modName}>{term.ability}</span>({term.value})
        </span>
      );
    case 'class':
      return (
        <span key={index} className={styles.classColor}>
          <span className={styles.modName}>{term.ability}</span>({term.value})
        </span>
      );
    case 'race':
      return (
        <Stack key={index} direction={StackDirection.Horizontal} className={styles.raceColor}>
          {term.value}
          {/* <span style={{ fontSize: 'xx-small' }}> {term.race}</span> */}
          <Stack direction={StackDirection.Vertical} justifyContent="center" className={styles.modName}>
            <span>from</span>
            <span>race</span>
          </Stack>
        </Stack>
      );
    default:
      return null;
  }
};

const getFormulaElements = (formula: Formula): JSX.Element[] =>
  formula.terms.reduce<JSX.Element[]>((elements, term, index) => {
    const element: JSX.Element | null = toFormulaElement(term, index);

    if (element) {
      //NOTE: do not add sign before first element
      if (index > 0) {
        // sign
        elements.push(
          <span key={'sign_' + index} className={styles.signColor}>
            +
          </span>,
        );
      }

      // value
      elements.push(element);
    }

    return elements;
  }, []);
