import { useHelper } from '@react-three/drei';
import React, { useRef } from 'react';
import { DirectionalLightHelper, SpotLightHelper } from 'three';

export const Lights: React.FC = (): JSX.Element => {
  // const directionalLight = useRef(null!);
  // useHelper(directionalLight, DirectionalLightHelper, undefined, 'green');

  // const spotLight1 = useRef(null!);
  // useHelper(spotLight1, SpotLightHelper, 'teal');

  // const spotLight2 = useRef(null!);
  // useHelper(spotLight2, SpotLightHelper, 'red');

  return (
    <>
      <ambientLight color={0xffffff} intensity={0.3} />
      <directionalLight
        // ref={directionalLight}
        color={0xffffff}
        intensity={0.3}
        position={[-300, 1000, 300]}
      />
      <spotLight
        // ref={spotLight1}
        color={0xffffff}
        intensity={0.95}
        position={[70, 100, -100]}
        target-position={[0, 0, 0]}
        castShadow={true}
        shadow-camera-near={0.1}
        shadow-camera-far={300}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      <spotLight
        // ref={spotLight2}
        color={0xffffff}
        intensity={0.5}
        position={[-70, 10, 70]}
        target-position={[0, 0, 0]}
        castShadow={false}
      />
    </>
  );
};
