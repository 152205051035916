export const HeroNames = [
  'Aflawn',
  'Alar',
  'Alun',
  'Dyved',
  'Amren',
  'Anghawd',
  'Annwas',
  'Annyanniawc',
  'Anoeth',
  'Veidawg',
  'Ardderchawg',
  'Prydain',
  'Ardwyad',
  'Atheu',
  'Aunwas',
  'Adeiniawc',
  'Baeddan',
  'Bedwyr',
  'Bedyw',
  'Beli',
  'Berth',
  'Berwyn',
  'Bradwen',
  'Brvssethach',
  'Brys',
  'Bwlch',
  'Cadell',
  'Calcas',
  'Carneddyr',
  'Cas',
  'Cast',
  'Rhvmi',
  'Cleddyf',
  'Difwlch',
  'Connyn',
  'Corvil',
  'Bervach',
  'Coth',
  'Cradawc',
  'Cuhert',
  'Dadweir',
  'Dallpenn',
  'Daere',
  'Dalldav',
  'Digon',
  'Dirmyg',
  'Dorath',
  'Drem',
  'Dremidyd',
  'Drudwas',
  'Drustwrn',
  'Hayarn',
  'Duach',
  'Dwnn',
  'Diessic',
  'Unbenn',
  'Dygyflwng',
  'Dyvel',
  'Dyvynwal',
  'Moel',
  'Ebrei',
  'Echel',
  'Vorddwyttwll',
  'Edeyrn',
  'Eflam',
  'Eiddon',
  'Vawr',
  'Vrydic',
  'Eidyol',
  'Eiladyr',
  'Eli',
  'Elidyr',
  'Gyvarwydd',
  'Ely',
  'Amyr',
  'Erbin',
  'Ergyryad',
  'Eri',
  'Erim',
  'Ermid',
  'Esgeir',
  'Gulhwch',
  'Govvnkawn',
  'Etmic',
  'Eus',
  'Fflergant',
  'Ffleudor',
  'Gadwy',
  'Gallcoyt',
  'Govynynat',
  'Ganastyr',
  'Kanllaw',
  'Garanwyn',
  'Garselit',
  'Wyddel',
  'Garwyli',
  'Geraint',
  'Gerenhir',
  'Gilda',
  'Gilla',
  'Coes',
  'Hydd',
  'Gleddyf',
  'Kvfwlch',
  'Glewlwyd',
  'Gavaelvawr',
  'Glust',
  'Glustveinad',
  'Glwyddyn',
  'Saer',
  'Glythwyr',
  'Gobrwy',
  'Gormant',
  'Gors',
  'Gant-Ewin',
  'Goryon',
  'Govynyon',
  'Hen',
  'Grathach',
  'Greid',
  'Greidawl',
  'Greidawl',
  'Galldonvd',
  'Grudiwyn',
  'Gorr',
  'Grychwr',
  'Gusg',
  'Gwadyn',
  'Odveith',
  'Gwadyn',
  'Ossol',
  'Gwalchmai',
  'Gwalhaved',
  'Gwallgoyc',
  'Gware',
  'Gwallt',
  'Euryn',
  'Gwawrddur',
  'Kyrvach',
  'Gweir',
  'Gweir',
  'Datharwenniddawg',
  'Gweir',
  'Gwrhyd',
  'Ennwir',
  'Gweir',
  'Paladyr',
  'Hir',
  'Gwennwynwyn',
  'Gwestad',
  'Gwevyl',
  'Gwiawn',
  'Llvgad',
  'Cath',
  'Gwrbothu',
  'Hen',
  'Gwrdinal',
  'Gwrhyr',
  'Gwarthegvras',
  'Gwrhyr',
  'Gwastawd',
  'leithoedd',
  'Gwrvan',
  'Gwallt',
  'Avwyn',
  'Gwryon',
  'Gwyar',
  'Gwyddawg',
  'Gwyddneu',
  'Astrus',
  'Gwyddrud',
  'Gwydre',
  'Gwyngad',
  'Gwynhan',
  'Gwynn',
  'Gwynn',
  'Godyvron',
  'Gwystyl',
  'Gwythawc',
  'Gwyr',
  'Gwythyr',
  'Henbedestyr',
  'Henwas',
  'Henwas',
  'Adeinawg',
  'Hettwn',
  'Talarvant',
  'Hir',
  'Amreu',
  'Hir',
  'Eiddvl',
  'Hiratrwm',
  'Hirerwm',
  'Huarwar',
  'Hueil',
  'Hunabwy',
  'Hyveidd',
  'Unllenn',
  'Iscawin',
  'Iscovan',
  'Hael',
  'Isperyr',
  'Hwingath',
  'Justic',
  'Kado',
  'Kai',
  'Kaw',
  'Kedarn',
  'Kelcoed',
  'Keli',
  'Kelin',
  'Kilydd',
  'Ganhastyr',
  'Kimin',
  'Cov',
  'Kueli',
  'Kulvanawyd',
  'Kyfwlch',
  'Kymvas',
  'Kynddelig',
  'Kyvarwydd',
  'Kyndrwyn',
  'Kynedyr',
  'Wyllt',
  'Kynwas',
  'Curvagyl',
  'Kynwyl',
  'Sant',
  'Kynyr',
  'Keinvarvawc',
  'laen',
  'Llary',
  'Llawnrodded',
  'Varvawc',
  'Llawr',
  'Eilerw',
  'Llenlleawg',
  'Wyddel',
  'Lloch',
  'Llawwynnyawc',
  'Lluber',
  'Beuthach',
  'Llwch',
  'Llawwynnyawg',
  'Llwybyr',
  'Llwyddeu',
  'Llyr',
  'Llysgadrudd',
  'Emys',
  'Mabsant',
  'Mael',
  'Maelwys',
  'Manawyddan',
  'Medyr',
  'Meilic',
  'Menestyr',
  'Menw',
  'Methredydd',
  'Moren',
  'Moren',
  'Mynawc',
  'Morgant',
  'Hael',
  'Morvran',
  'Nav',
  'Nav',
  'Gyssevin',
  'Naw',
  'Neb',
  'Ner',
  'Nerth',
  'Nerthach',
  'Nes',
  'Nodawl',
  'Varyf',
  'Twrch',
  'Nudd',
  'Nwython',
  'Nwyvre',
  'Oedd',
  'Ol',
  'Olwydd',
  'Osla',
  'Gyllellvawr',
  'Ovan',
  'Panawr',
  'Pen',
  'Bagad',
  'Panon',
  'Pen',
  'Llarcau',
  'Percos',
  'Perif',
  'Poch',
  'Reidwn',
  'Arwy',
  'Rheidwn',
  'Rheu',
  'Rhwyd',
  'Dyrys',
  'Rhun',
  'Rhun',
  'Rhudwern',
  'Ricca',
  'Roycol',
  'Ruawn',
  'Pebyr',
  'Saidi',
  'Samson',
  'Vinsych',
  'Sandde',
  'Bryd',
  'Angel',
  'Saranhon',
  'Sawvl',
  'Ben',
  'Uchel',
  'Sefwlch',
  'Seithved',
  'Sel',
  'Selgi',
  'Selyf',
  'Sgilti',
  'Yscawndroed',
  'Siawn',
  'Sinnoch',
  'Smoit',
  'Sol',
  'Sugnedydd',
  'Sugyn',
  'Sulyen',
  'Talaryant',
  'Tathal',
  'Twyll',
  'Goleu',
  'Tegid',
  'Tegvan',
  'Gloff',
  'Tegyr',
  'Talgellawg',
  'Teirgwaedd',
  'Teirnon',
  'Twryf',
  'Bliant',
  'Teithi',
  'Hen',
  'Teregud',
  'Tryffin',
  'Twrch',
  'Uchtryd',
  'Uchtryd',
  'Ardywad',
  'Kad',
  'Uchtryd',
  'Varyt',
  'Draws',
  'Yscudydd',
  'Yskyrdav',
];
