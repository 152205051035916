import { DiceType } from '../../../api/DnD/dices';
import { DiceDescription, DiceInfo, DiceOptions } from '../diceTypes';
import { FACE_TEXTS } from '../r3fConstants';
import { createDiceTextures, createGeometry, createShape } from '../utils';

const createDescription = (): DiceDescription => {
  const p = (1 + Math.sqrt(5)) / 2;
  const q = 1 / p;

  const desc: DiceDescription = {
    type: DiceType.d12,
    tab: 0.2,
    af: -Math.PI / 4 / 2,
    chamfer: 0.968,
    vertices: [
      [0, q, p],
      [0, q, -p],
      [0, -q, p],
      [0, -q, -p],
      [p, 0, q],
      [p, 0, -q],
      [-p, 0, q],
      [-p, 0, -q],
      [q, p, 0],
      [q, -p, 0],
      [-q, p, 0],
      [-q, -p, 0],
      [1, 1, 1],
      [1, 1, -1],
      [1, -1, 1],
      [1, -1, -1],
      [-1, 1, 1],
      [-1, 1, -1],
      [-1, -1, 1],
      [-1, -1, -1],
    ],
    faces: [
      [2, 14, 4, 12, 0, 1],
      [15, 9, 11, 19, 3, 2],
      [16, 10, 17, 7, 6, 3],
      [6, 7, 19, 11, 18, 4],
      [6, 18, 2, 0, 16, 5],
      [18, 11, 9, 14, 2, 6],
      [1, 17, 10, 8, 13, 7],
      [1, 13, 5, 15, 3, 8],
      [13, 8, 12, 4, 5, 9],
      [5, 4, 14, 9, 15, 10],
      [0, 12, 8, 10, 16, 11],
      [3, 19, 7, 17, 1, 12],
    ],
    scaleFactor: 0.9,
    values: 12,
    faceTexts: FACE_TEXTS,
    textMargin: 1.0,
    mass: 350,
  };

  return desc;
};

const description = createDescription();

const options: DiceOptions = {
  size: 2.5,
  backColor: '#e0ca3c',
  fontColor: '#2d3047',
};

const textures = createDiceTextures(description, options);
const geometry = createGeometry(options.size, description);

export const D12: DiceInfo = {
  textures,
  description,
  options,
  geometry,
  args: createShape(description, options.size),
};
