import * as React from 'react';
import { Button } from '../Button/Button';
import { Stack, StackDirection } from '../Stack';
import styles from './ToggleSelector.module.css';
import { useScrollToElement } from '../../../hooks/useScrollToElement';

type Props<T> = {
  items: T[];
  initialSelection?: T | undefined;
  onItemSelected: (newItem: T | undefined) => void;
  getItemTitle: (item: T) => string;
  getItemKey: (item: T) => string;
  itemClassName?: string | undefined;
  hasAllButton?: boolean;
};

export function ToggleSelector<T>({
  items,
  initialSelection,
  itemClassName,
  hasAllButton,
  getItemTitle,
  getItemKey,
  onItemSelected,
}: Props<T>) {
  const [currentFilter, setCurrentFilter] = React.useState<T | undefined>(initialSelection);
  const scrollToElement = useScrollToElement();

  React.useEffect(() => {
    setCurrentFilter(initialSelection);
  }, [initialSelection]);

  const onItemClicked = (item: T | undefined) => {
    setCurrentFilter(item);
    onItemSelected(item);
  };

  return (
    <div className={styles.conteiner}>
      <Stack direction={StackDirection.Horizontal} gap={4}>
        {hasAllButton !== false && (
          <div
            key="show-everything"
            ref={(ref) => {
              if (undefined === currentFilter && ref) {
                scrollToElement(ref);
              }
            }}>
            <Button
              onClick={() => onItemClicked(undefined)}
              className={currentFilter === undefined ? styles.selected : undefined}>
              All
            </Button>
          </div>
        )}
        {items.map((value) => (
          <div
            key={getItemKey(value)}
            ref={(ref) => {
              if (value === currentFilter && ref) {
                scrollToElement(ref);
              }
            }}>
            <Button
              onClick={() => onItemClicked(value)}
              className={currentFilter === value ? styles.selected : undefined}>
              <div className={itemClassName}>{getItemTitle(value)}</div>
            </Button>
          </div>
        ))}
      </Stack>
    </div>
  );
}
