import Immutable from 'immutable';
import { CardInfo } from '../redux/state';
import { notEmpty } from '../utils/arrayUtils';
import { HeroClass } from './DnD/classes';
import { Race } from './DnD/races';
import { v4 as uuidv4 } from 'uuid';

// NOTE: maybe it is better to move this outside of the storage logic
const IMAGE_ROOT = 'images/inventory/';

type CardRawData = {
  title: string;
  description: string;
  type?: string;
  image?: string;
  class?: string;
  race?: string;
  tag?: string;
  charname?: string;
};

const parseCommaSeparatedArray = <T>(data: string, guard: (value: string) => boolean): T[] => {
  const items: string[] = data.split(',').map((item) => item.trim());
  return items
    .filter((item) => {
      const isValid = guard(item);

      if (!isValid) {
        console.warn(`Cannot understand what [${item}] is. Skipping.`);
      }

      return isValid;
    })
    .map((item) => item as unknown as T);
};

const isClass = (value: string): value is HeroClass => Object.values(HeroClass).includes(value as HeroClass);
const isRace = (value: string): value is Race => Object.values(Race).includes(value as Race);

const getAllCardsAsync = async (path: string): Promise<Immutable.List<CardInfo> | null> => {
  const response = await fetch(path);
  const data = await response.json();

  if (!data?.cards) {
    return null;
  }

  const rawData = data.cards as CardRawData[];

  const cards: CardInfo[] = rawData
    .filter(({ title, description }) => !!title && !!description)
    .map(({ title, description, type, image, class: heroClass, race, tag, charname }) => {
      const classes = heroClass ? parseCommaSeparatedArray<HeroClass>(heroClass, isClass) : undefined;
      const races = race ? parseCommaSeparatedArray<Race>(race, isRace) : undefined;
      const tags = tag ? parseCommaSeparatedArray<string>(tag, notEmpty) : undefined;
      const charnames = charname ? parseCommaSeparatedArray<string>(charname, notEmpty) : undefined;

      return {
        id: uuidv4(),
        type: type?.toLowerCase(),
        title,
        description,
        image: image && `${IMAGE_ROOT}${image}.png`,
        classes,
        races,
        tags,
        charnames,
      };
    });

  return Immutable.List<CardInfo>(cards);
};

export type CardsStorage = {
  getAllCardsAsync: (path: string) => Promise<Immutable.List<CardInfo> | null>;
};

export const createCardsStorage = (): CardsStorage => ({ getAllCardsAsync });
