import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Ability, AbilityType } from '../../api/DnD/abilities';
import { calcAbilityModifier, getCostOfIncrease, getRemainingPoints } from '../../api/DnD/abilityScoreCalculator';
import { getACModValue, HeroClass } from '../../api/DnD/classes';
import { getInitialHP } from '../../api/DnD/hpCalculator';
import { getRaceModificator, Race } from '../../api/DnD/races';
import { AppState, CharacterInfo, CharacterState } from '../state';

export const selectCharacterInfo = (state: AppState): CharacterInfo => state.character.characterInfo;

export const selectAbilities = (state: AppState): Immutable.Map<AbilityType, number> => state.character.abilities;

export const selectRemainingAbilityPoints = createSelector(selectAbilities, (abilities) =>
  getRemainingPoints(abilities),
);

const selectRace = (state: AppState): Race => state.character.characterInfo.race;

export const selectAbilityItems = createSelector(
  [selectAbilities, selectRace],
  (abilityToValue, race): Immutable.Map<AbilityType, Ability> =>
    abilityToValue.map((baseValue, type) => {
      const raceMod = getRaceModificator(race, type);
      const mod = calcAbilityModifier(baseValue, type, race);

      return {
        type,
        baseValue,
        raceMod,
        mod,
        costOfIncrease: getCostOfIncrease(baseValue),
      };
    }),
);

//TODO: that is not efficient, read https://redux.js.org/usage/deriving-data-selectors#selector-factories
export const selectAbilityByType = createSelector(
  [selectAbilityItems, (state: AppState, abilityType: AbilityType) => abilityType],
  (abilities: Immutable.Map<AbilityType, Ability>, abilityType: AbilityType): Ability | undefined =>
    abilities.get(abilityType),
);

export type FormulaTerm =
  | {
      source: 'initial';
      value: number;
    }
  | {
      source: 'ability';
      value: number;
      ability: AbilityType;
    }
  | {
      source: 'race';
      value: number;
      race: Race;
    }
  | {
      source: 'class';
      value: number;
      class: HeroClass;
      ability: AbilityType;
    };

export type Formula = {
  terms: FormulaTerm[];
  result: number;
};

const calcResult = (terms: FormulaTerm[]) => terms.reduce((acc, term) => acc + term.value, 0);

const selectHPFormula = createSelector(
  [selectCharacterInfo, (state: AppState) => state.character.abilities],
  (characterInfo: CharacterInfo, abilities: Immutable.Map<AbilityType, number>): Formula => {
    const baseCON = abilities.get(AbilityType.CON) || 0;
    const constitutionMod = calcAbilityModifier(baseCON, AbilityType.CON, characterInfo.race);

    const terms: FormulaTerm[] = [
      { source: 'initial', value: getInitialHP(characterInfo.class) },
      { source: 'ability', value: constitutionMod, ability: AbilityType.CON },
    ];

    if (characterInfo.race === Race.HillDwarf) {
      const raceHPMod = 1; //NOTE Hill Dwarfs gain +1 HP per level

      terms.push({ source: 'race', value: raceHPMod, race: Race.HillDwarf });
    }

    return {
      terms,
      result: calcResult(terms),
    };
  },
);

const selectArmorClass = createSelector(
  [selectCharacterInfo, selectAbilityItems],
  (characterInfo: CharacterInfo, abilities: Immutable.Map<AbilityType, Ability>): Formula => {
    //INFO: https://www.dndbeyond.com/sources/basic-rules/step-by-step-characters#ArmorClass
    //TODO: update this calculations when add items, magic or subclasses

    const dexMod = abilities.get(AbilityType.DEX)?.mod || 0;

    const terms: FormulaTerm[] = [
      { source: 'initial', value: 10 },
      { source: 'ability', value: dexMod, ability: AbilityType.DEX },
    ];

    const acMod = getACModValue(characterInfo.class, abilities);
    if (acMod) {
      terms.push({ source: 'class', value: acMod.value, ability: acMod.ability, class: characterInfo.class });
    }

    return {
      terms,
      result: calcResult(terms),
    };
  },
);

export const useAbilities = (): Immutable.Map<AbilityType, Ability> => useSelector(selectAbilityItems);

export const useFindAbilityByType = (abilityType: AbilityType | undefined): Ability | undefined =>
  useSelector((state: AppState) => (abilityType ? selectAbilityByType(state, abilityType) : undefined));

export const useRemainingAbilityPoints = (): number => useSelector(selectRemainingAbilityPoints);

export const useCharacterInfo = (): CharacterInfo => useSelector(selectCharacterInfo);

export const useCharacterState = (): CharacterState =>
  useSelector<AppState, CharacterState>((state) => state.character);

export const useHPFormula = (): Formula => useSelector<AppState, Formula>(selectHPFormula);

export const useArmorClass = (): Formula => useSelector<AppState, Formula>(selectArmorClass);
