import * as React from 'react';
import { useAvailableTags } from '../../redux/cards/selectors';
import { useAlterntaeArrow, useCurrentTag, useOrthographicCamera, useShowAvatar } from '../../redux/settings/selectors';
import { SettingsThunk } from '../../redux/settings/thunks';
import { useAppDispatch } from '../../redux/store';
import { Button } from '../common/Button/Button';
import { Checkbox } from '../common/Checkbox/Checkbox';
import { InputDialog } from '../common/InputDialog/InputDialog';
import { ItemSelector } from '../common/ItemSelector/ItemSelector';
import { Panel } from '../common/Panel/Panel';
import { Stack, StackDirection } from '../common/Stack';

import styles from './SettingsPanel.module.css';

type Props = {
  onDismiss: () => void;
};

export const SettingsPanel: React.FC<Props> = ({ onDismiss }) => {
  const dispatch = useAppDispatch();
  const showAvatar = useShowAvatar();
  const orthographicCameraEnabled = useOrthographicCamera();
  const availableTags = useAvailableTags();
  const currentTag = useCurrentTag();
  const alterntaeArrow = useAlterntaeArrow();
  const tags = [undefined, ...availableTags]; //NOTE: empty string added as None tag

  const [showImportDialog, setShowImportDialog] = React.useState(false);

  return (
    <Panel
      panelStyle="cover"
      panelPlacement="right"
      showOverlay={true}
      closePlacement={'bottomRight'}
      backInsteadClose={false}
      onDismiss={onDismiss}>
      <div className={styles.container}>
        <Stack direction={StackDirection.Vertical} gap={10}>
          <div className={styles.title}>Common Settings</div>
          {/* Avatar */}
          <Checkbox
            label="Show Avatar"
            checked={showAvatar}
            onChange={() => dispatch(SettingsThunk.setShowAvatar(!showAvatar))}
          />

          {/* Orthographic Camera */}
          <Checkbox
            label="Orthographic Camera"
            checked={orthographicCameraEnabled}
            onChange={() => dispatch(SettingsThunk.setOrthographicCameraEnabled(!orthographicCameraEnabled))}
          />

          {/* Alterntae Arrow */}
          <Checkbox
            label="Alterntae Arrow"
            checked={alterntaeArrow}
            onChange={() => dispatch(SettingsThunk.setAlterntaeArrow(!alterntaeArrow))}
          />
        </Stack>

        <Stack direction={StackDirection.Vertical} gap={10}>
          {/* Game Tag for Inventory */}
          <div className={styles.title}>Game Tag</div>
          <ItemSelector
            items={tags}
            currentItem={currentTag}
            selectorTitle="Select Game Tag"
            displayAs="list"
            panelPlacement="right"
            fullWidth={true}
            onItemChanged={(selectedItem) => dispatch(SettingsThunk.setGameTag(selectedItem || undefined))}
            renderItem={(tag) => <div className={styles.gameTag}>{tag || 'None'}</div>}
          />
        </Stack>

        <Stack direction={StackDirection.Vertical} gap={10}>
          {/* Import Character */}
          <div className={styles.title}>Character</div>
          <Button
            style={{ padding: 8 }}
            onClick={() => {
              setShowImportDialog(true);
            }}>
            Import Character
          </Button>
          {showImportDialog && (
            <InputDialog
              title="Import Character"
              description="Paste here URL for import"
              okText="Import"
              onDismiss={() => {
                setShowImportDialog(false);
              }}
              onSubmit={(text) => {
                if (text) {
                  dispatch(SettingsThunk.tryImportCharacter(text));
                  onDismiss();
                }
              }}
            />
          )}
        </Stack>
      </div>
    </Panel>
  );
};
