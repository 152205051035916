import * as React from 'react';
import { Button } from '../Button/Button';
import { Dialog } from '../Dialog/Dialog';
import { Icon } from '../Icon';
import { Stack, StackDirection } from '../Stack';
import { TextBox } from '../TextBox';
import CloseIcon from '../../../assets/icons/close.svg';

import styles from './InputDialog.module.css';

type Props = {
  title: string;
  description: string;
  okText?: string;
  cancelText?: string;
  onSubmit: (enteredText?: string) => void;
  onDismiss: () => void;
};

export const InputDialog: React.FC<Props> = ({
  title,
  description,
  okText = 'Ok',
  cancelText = 'Cancel',
  onSubmit,
  onDismiss,
}) => {
  const [text, setText] = React.useState<string | undefined>();

  return (
    <Dialog onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div>{title}</div>
          <Button className={styles.closeButton} onClick={onDismiss}>
            <Icon width={12} height={12} url={CloseIcon} title="Close" />
          </Button>
        </div>

        <label>{description}</label>
        <TextBox
          autoFocus={false}
          submitOnBlur={true}
          resetOnEmpty={true}
          spellCheck={false}
          className={styles.text}
          onChange={(value) => setText(value)}
          onSubmit={(value) => setText(value)}
          onReset={() => setText(undefined)}
        />

        <Stack direction={StackDirection.Horizontal} gap={12} justifyContent="center">
          <Button className={styles.button} onClick={() => onDismiss()}>
            {cancelText}
          </Button>
          <Button
            className={styles.button}
            disabled={!text}
            onClick={() => {
              onSubmit(text);
              onDismiss();
            }}>
            {okText}
          </Button>
        </Stack>
      </div>
    </Dialog>
  );
};
