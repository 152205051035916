import React from 'react';
import { useAppDispatch } from '../../redux/store';
import { DiceThunk } from '../../redux/dices/thunks';
import { Ability, toAbilityName } from '../../api/DnD/abilities';

import styles from './AbilityItem.module.css';

type Props = {
  ability: Ability;
  selected: boolean;
  pulseWhenSelected: boolean;
};

export const AbilityItem: React.FC<Props> = ({ ability, selected, pulseWhenSelected }) => {
  const { type, baseValue, raceMod, mod } = ability;

  const dispatch = useAppDispatch();
  const setRollModifier = () => dispatch(DiceThunk.setRollModifier(type));

  return (
    <div
      className={`${styles.container} ${selected ? styles.selected : undefined}  ${
        pulseWhenSelected && selected ? styles.pulse : undefined
      }`}
      onMouseDown={setRollModifier}
      onTouchStart={setRollModifier}>
      <div className={styles.ability}>{toAbilityName(type).toUpperCase()}</div>
      <div className={styles.mod}>{mod >= 0 ? `+${mod}` : mod}</div>
      <div className={styles.abilityValue}>{baseValue + raceMod}</div>
    </div>
  );
};
