import React, { useEffect, useRef } from 'react';
import { EffectCoverflow } from 'swiper';
import {
  useAvailableCardTypes,
  useAvailableCardsByType,
  useItemTypeFilter,
  useSelectedCard,
} from '../../redux/cards/selectors';
import { CardInfo } from '../../redux/state';
import { Icon } from '../common/Icon';
import { Panel, PanelPlacement } from '../common/Panel/Panel';
import { Stack, StackDirection } from '../common/Stack';
import styles from './InventoryPanel.module.css';
// Import Swiper React components
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { CoverflowEffectOptions } from 'swiper/types';
import { useScrollToElement } from '../../hooks/useScrollToElement';
import { CardThunk } from '../../redux/cards/thunks';
import { useAppDispatch } from '../../redux/store';
import { toDisplayString } from '../../utils/stringUtils';
import { Card } from '../Card/Card';
import { ToggleSelector } from '../common/ToggleSelector/ToggleSelector';

type Props = {
  panelPlacement?: PanelPlacement | undefined;
  onDismiss: () => void;
};

const coverflowEffectOptions: CoverflowEffectOptions = {
  rotate: 65,
  stretch: 0,
  depth: 100,
  modifier: 1,
  slideShadows: false,
};

export const InventoryPanel: React.FC<Props> = ({ panelPlacement, onDismiss }) => {
  const dispatch = useAppDispatch();
  const cardTypes = useAvailableCardTypes();
  const selectedCard = useSelectedCard();
  const currentFilter = useItemTypeFilter();
  const filteredItems = useAvailableCardsByType(currentFilter);
  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    //NOTE: when selectedCard changes we swipe to it
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const foundCardIndex = filteredItems.indexOf(selectedCard!);
    const slideToIndex = foundCardIndex >= 0 ? foundCardIndex : 0;
    const swiper = swiperRef.current?.swiper;

    if (swiper && filteredItems.count() > slideToIndex) {
      swiper.slideToLoop(slideToIndex);
    }
  }, [filteredItems, selectedCard]);

  const onFilterItems = (filter: string | undefined) => {
    dispatch(CardThunk.setFilter(filter, undefined));
  };

  return (
    <Panel
      onDismiss={onDismiss}
      panelPlacement={panelPlacement}
      showOverlay={panelPlacement === 'bottom'}
      closePlacement={panelPlacement === 'left' ? 'bottomLeft' : 'bottomRight'}>
      <div className={styles.swiperContainer}>
        {/* Card Swiper */}
        <Swiper
          ref={swiperRef}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView="auto"
          effect="coverflow"
          coverflowEffect={coverflowEffectOptions}
          modules={[EffectCoverflow]}
          onRealIndexChange={(swiper) => {
            if (swiper.realIndex >= 0 && swiper.realIndex < filteredItems.count()) {
              dispatch(CardThunk.setFilter(currentFilter, filteredItems.get(swiper.realIndex)));
            }
          }}>
          {filteredItems.map((card) => (
            <SwiperSlide key={card.id} className={styles.cardSlide}>
              {({ isActive }) => (
                <Card
                  card={card}
                  contentClassName={styles.card}
                  containerClassName={isActive ? undefined : styles.inactive}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Item Filter */}
      {cardTypes.length > 1 && (
        <ToggleSelector
          items={cardTypes}
          initialSelection={currentFilter}
          onItemSelected={onFilterItems}
          getItemTitle={toDisplayString}
          getItemKey={(item) => item}
        />
      )}

      {/* Inventory*/}
      <div className={styles.inventoryContainer}>
        <InventoryGrid
          cards={filteredItems}
          selectedItem={selectedCard ?? filteredItems.first()}
          onItemSelected={(_item, index) => {
            const swiper = swiperRef.current?.swiper;
            if (swiper && swiper.slides.length > index) {
              swiper.slideToLoop(index);
            }
          }}
        />
      </div>
    </Panel>
  );
};

type InventoryGridProps = {
  cards: Immutable.List<CardInfo>;
  selectedItem?: CardInfo;
  onItemSelected: (item: CardInfo, index: number) => void;
};

const InventoryGrid: React.FC<InventoryGridProps> = ({ cards, selectedItem, onItemSelected }) => {
  const scrollToElement = useScrollToElement();

  return (
    <div className={styles.inventoryGrid}>
      {cards.map((item, index) => (
        // Inventory Item
        <div
          key={item.id}
          ref={(ref) => {
            if (item === selectedItem && ref) {
              scrollToElement(ref);
            }
          }}
          className={item === selectedItem ? `${styles.item} ${styles.selected}` : styles.item}
          onClick={() => onItemSelected(item, index)}>
          <Stack direction={StackDirection.Vertical} alignItems="center" justifyContent="flex-end" gap={4}>
            <Icon url={item.image || 'images/inventory/scroll.png'} width={42} height={42} />
            <div style={{ fontSize: 10 }}>{item.title}</div>
          </Stack>
        </div>
      ))}
    </div>
  );
};
