import * as Immutable from 'immutable';
import { createReducer, describeAction } from 'ts-describe-action';
import { DicesState, RollResults, RollType } from '../state';
import { ActionTypes } from '../actionTypes';
import { DiceType } from '../../api/DnD/dices';
import { Reducer } from 'redux';
import { AbilityType } from '../../api/DnD/abilities';

const setDicesToRoll = describeAction(
  'SET_DICES_TO_ROLL',
  (prev: DicesState, dicesToRoll: Immutable.Map<DiceType, number>): DicesState => ({
    ...prev,
    dicesToRoll: dicesToRoll.isEmpty() ? dicesToRoll.set(DiceType.d20, 1) : dicesToRoll,
    rollType: 'normal',
    rollResults: undefined,
  }),
);

const setRollResults = describeAction(
  'SET_ROLL_RESULTS',
  (prev: DicesState, rollResults: RollResults | undefined): DicesState => ({
    ...prev,
    rollResults,
  }),
);

const setRollInProgress = describeAction(
  'SET_ROLL_INPROGRESS',
  (prev: DicesState, rollInProgress: boolean): DicesState => ({
    ...prev,
    rollInProgress,
  }),
);

const setRollModifier = describeAction(
  'SET_ROLL_MODIFIER',
  (prev: DicesState, rollModifier: AbilityType | undefined): DicesState => ({ ...prev, rollModifier }),
);

const setResetAbilityOnNextRoll = describeAction(
  'SET_RESET_ABILITY_ON_NEXT_ROLL',
  (prev: DicesState, resetAbilityOnNextRoll: boolean): DicesState => ({ ...prev, resetAbilityOnNextRoll }),
);

const setResultModifier = describeAction(
  'SET_RESULT_MODIFIER',
  (prev: DicesState, resultModifier: number | undefined): DicesState => ({ ...prev, resultModifier }),
);

const setRollType = describeAction(
  'SET_ROLL_TYPE',
  (prev: DicesState, rollType: RollType): DicesState => ({
    ...prev,
    rollType,
    dicesToRoll: rollType !== 'normal' ? Immutable.Map<DiceType, number>().set(DiceType.d20, 2) : prev.dicesToRoll,
    rollResults: undefined,
  }),
);

export const DiceAction = {
  setDicesToRoll,
  setRollResults,
  setRollInProgress,
  setRollModifier,
  setResetAbilityOnNextRoll,
  setResultModifier,
  setRollType,
};

export type DiceActionType = ActionTypes<typeof DiceAction>;

export const createDicesReducer = (): Reducer<DicesState> =>
  createReducer(Object.values(DiceAction), {
    rollInProgress: false,
    dicesToRoll: Immutable.Map<DiceType, number>().set(DiceType.d20, 1),
    rollResults: undefined,
    rollModifier: undefined,
    resultModifier: undefined,
    rollType: 'normal',
  });
