export enum AbilityType {
  STR = 'STR',
  DEX = 'DEX',
  CON = 'CON',
  INT = 'INT',
  WIS = 'WIS',
  CHA = 'CHA',
}

export type Ability = {
  type: AbilityType;
  baseValue: number;
  raceMod: number;
  mod: number;
  costOfIncrease: number;
};

export const toAbilityName = (abilityType: AbilityType): string => {
  switch (abilityType) {
    case AbilityType.CHA:
      return 'Charisma';
    case AbilityType.CON:
      return 'Constitution';
    case AbilityType.DEX:
      return 'Dexterity';
    case AbilityType.INT:
      return 'Intelligence';
    case AbilityType.STR:
      return 'Strength';
    case AbilityType.WIS:
      return 'Wisdom';
  }
};
