import * as React from 'react';
import { DiceAction } from '../../redux/dices/reducer';
import { useResultModifier, useRollType } from '../../redux/dices/selectors';
import { useAppDispatch } from '../../redux/store';
import { CountSelector } from '../common/CountSelector/CountSelector';
import { Stack, StackDirection } from '../common/Stack';
import { Checkbox } from '../common/Checkbox/Checkbox';

import styles from './ResultModifier.module.css';

export const ResultModifier: React.FC = () => {
  const dispatch = useAppDispatch();
  const resultModifier = useResultModifier();
  const rollType = useRollType();

  //TODO: add advantage/disadvantage to analytics

  return (
    <Stack direction={StackDirection.Vertical} gap={10}>
      <div className={styles.container}>
        <div className={styles.item}>Roll Modifier</div>
        <div className={styles.item}>
          <CountSelector
            orientation="horizontal"
            value={resultModifier || 0}
            minValue={-100}
            maxValue={100}
            onValueChanged={(value) => dispatch(DiceAction.setResultModifier(value))}>
            <div className={styles.count}>
              <div>{resultModifier || 0}</div>
            </div>
          </CountSelector>
        </div>
      </div>
      <Checkbox
        label="Roll with Advantage"
        checked={rollType === 'advantage'}
        onChange={() => dispatch(DiceAction.setRollType(rollType === 'advantage' ? 'normal' : 'advantage'))}
      />
      <Checkbox
        label="Roll with Disadvantage"
        checked={rollType === 'disadvantage'}
        onChange={() => dispatch(DiceAction.setRollType(rollType === 'disadvantage' ? 'normal' : 'disadvantage'))}
      />
    </Stack>
  );
};
