import Immutable from 'immutable';

import { Reducer } from 'redux';
import { createReducer, describeAction } from 'ts-describe-action';
import { ActionTypes } from '../actionTypes';
import { PanelStack, PanelType, PanelsState } from './types';

type PushPanelPayload = {
  panelStack: PanelStack;
  panelType: PanelType;
};

const pushPanel = describeAction(
  'PUSH_PANEL',
  (prev: PanelsState, { panelStack, panelType }: PushPanelPayload): PanelsState => {
    const stack = prev.stacks.get(panelStack);

    return {
      ...prev,
      stacks: prev.stacks.set(panelStack, stack ? stack.push(panelType) : Immutable.List<PanelType>([panelType])),
    };
  },
);

const popPanel = describeAction('POP_PANEL', (prev: PanelsState, panelStack: PanelStack): PanelsState => {
  const stack = prev.stacks.get(panelStack);

  return stack && stack.count() > 0
    ? {
        ...prev,
        stacks: prev.stacks.set(panelStack, stack.pop()),
      }
    : prev;
});

const closePanel = describeAction(
  'CLOSE_PANEL',
  (prev: PanelsState, { panelStack, panelType }: PushPanelPayload): PanelsState => {
    const stack = prev.stacks.get(panelStack);
    if (!stack) {
      return prev;
    }

    const indexToRemove = stack.findIndex((item) => item === panelType);
    if (indexToRemove < 0) {
      return prev;
    }

    return {
      ...prev,
      stacks: prev.stacks.set(panelStack, stack.remove(indexToRemove)),
    };
  },
);

export const PanelsAction = {
  pushPanel,
  popPanel,
  closePanel,
};

export type PanelsActionType = ActionTypes<typeof PanelsAction>;

const getInitialState = (): PanelsState => {
  const initState: PanelsState = {
    stacks: Immutable.Map<PanelStack, Immutable.List<PanelType>>(),
  };

  return initState;
};

export const createPanelsReducer = (): Reducer<PanelsState> =>
  createReducer(Object.values(PanelsAction), getInitialState());
