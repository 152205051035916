import { Instances } from '@react-three/drei';
import React from 'react';
import { FrontSide } from 'three';
import { DiceType } from '../../api/DnD/dices';
import { D10 } from './diceData/Dice_D10';
import { D12 } from './diceData/Dice_D12';
import { D20 } from './diceData/Dice_D20';
import { D4 } from './diceData/Dice_D4';
import { D6 } from './diceData/Dice_D6';
import { D8 } from './diceData/Dice_D8';
import { DiceFrameCallback, DiceInstance, DiceStartCallback, DiceStopCallback } from './DiceInstance';
import { DiceInfo } from './diceTypes';
import { BodyNames } from './r3fConstants';
import { DiceSounds } from './sounds';

const MAX_DICE_INSTANCES = 5;

type Props = {
  diceType: DiceType;
  count: number;
  limit?: number;
  range?: number;
  diceSounds: DiceSounds | undefined;
  onFrame: DiceFrameCallback;
  onStarted: DiceStartCallback;
  onStopped: DiceStopCallback;
};

//NOTE: https://github.com/pmndrs/drei#instances
export const DiceInstances: React.FC<Props> = ({
  diceType,
  count,
  limit = MAX_DICE_INSTANCES,
  range = MAX_DICE_INSTANCES,
  diceSounds,
  onFrame,
  onStarted,
  onStopped,
}) => {
  const dice = toDiceInfo(diceType); //NOTE: won't cause re-render in other components, DiceInfo never changes, it is already cached on the start of the app

  //NOTE: https://threejs.org/docs/#api/en/materials/MeshPhongMaterial
  return (
    <Instances
      limit={limit} // Optional: max amount of items (for calculating buffer size)
      range={range} // Optional: draw-range
      receiveShadow={true}
      castShadow={true}
      geometry={dice.geometry}
      name={BodyNames.Dice}>
      {dice.textures.map((texture, index) => (
        <meshPhongMaterial
          key={index}
          attach={`material-${index}`} //without this, all faces will have the same texture (same number will be shown)
          side={FrontSide}
          specular={0x33474d}
          shininess={20}
          flatShading={true}
          map={texture}
        />
      ))}
      {createDiceInstances(dice, count, diceSounds, onFrame, onStarted, onStopped)}
    </Instances>
  );
};

const createDiceInstances = (
  diceInfo: DiceInfo,
  count: number,
  diceSounds: DiceSounds | undefined,
  onFrame: DiceFrameCallback,
  onStarted: DiceStartCallback,
  onStopped: DiceStopCallback,
) => {
  const diceElements: JSX.Element[] = [];

  for (let i = 0; i < count; i++) {
    diceElements.push(
      <DiceInstance
        key={`${diceInfo.description.type}-${i}`}
        instanceIndex={i}
        diceInfo={diceInfo}
        diceSounds={diceSounds}
        onFrame={onFrame}
        onStarted={onStarted}
        onStopped={onStopped}
      />,
    );
  }

  return diceElements;
};

const toDiceInfo = (type: DiceType): DiceInfo => {
  switch (type) {
    case DiceType.d4:
      return D4;
    case DiceType.d6:
      return D6;
    case DiceType.d8:
      return D8;
    case DiceType.d10:
      return D10;
    case DiceType.d12:
      return D12;
    case DiceType.d20:
      return D20;
  }
};
