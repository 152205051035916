import * as React from 'react';

type Props = {
  width: number;
  height: number;
  url: string;
  title?: string;
  children?: React.ReactNode;
};

export const Icon: React.FC<Props> = ({ url, width, height, title, children }) => (
  <div
    title={title}
    style={{
      width,
      height,
      background: `no-repeat center / contain url('${url}')`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    {children}
  </div>
);
