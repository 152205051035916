export const createResizeObserver = (callback: (width: number, height: number) => void): ResizeObserver =>
  new ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.contentBoxSize) {
        // Firefox implements `contentBoxSize` as a single content rect, rather than an array
        const contentBoxSize: ResizeObserverSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize;

        if (contentBoxSize) {
          const { inlineSize: width, blockSize: height } = contentBoxSize;
          callback(width, height);
        }
      } else {
        const { width, height } = entry.contentRect;
        callback(width, height);
      }
    }
  });
