import * as React from 'react';
import { ChangeEvent } from 'react';

type Props = {
  initialValue?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onReset?: (value: string) => void;
  className?: string;
  submitOnBlur?: boolean;
  resetOnEmpty?: boolean;
  spellCheck?: boolean;
};

export const TextBox: React.FC<Props> = ({
  initialValue = '',
  autoFocus = true,
  className,
  submitOnBlur,
  resetOnEmpty,
  spellCheck,
  onChange,
  onSubmit,
  onReset,
}) => {
  const [text, setText] = React.useState(initialValue);
  const textRef = React.useRef<HTMLInputElement | null>(null);
  const suspendEventsRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    setText(initialValue);
  }, [initialValue]);

  const change = React.useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target;
      setText(value);
      onChange && onChange(value);
    },
    [onChange],
  );

  const reset = React.useCallback(() => {
    if (suspendEventsRef.current === true) {
      return;
    }

    suspendEventsRef.current = true;

    textRef.current?.blur();
    setText(initialValue);

    onReset && onReset(initialValue);

    suspendEventsRef.current = false;
  }, [onReset, initialValue]);

  const submit = React.useCallback(() => {
    if (suspendEventsRef.current === true) {
      return;
    }

    if (resetOnEmpty && !text) {
      reset();
      return;
    }

    suspendEventsRef.current = true;

    textRef.current?.blur();
    onSubmit && onSubmit(text);

    suspendEventsRef.current = false;
  }, [resetOnEmpty, onSubmit, text, reset]);

  const keyDown = React.useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      switch (evt.code) {
        case 'Enter':
          submit();
          break;
        case 'Escape':
          reset();
          break;
      }
    },
    [reset, submit],
  );

  return (
    <input
      ref={textRef}
      type="text"
      value={text}
      className={className}
      autoFocus={autoFocus}
      onChange={change}
      onBlur={submitOnBlur ? submit : reset}
      onKeyDown={keyDown}
      spellCheck={spellCheck}
    />
  );
};
