import * as React from 'react';
import { getRandomName } from '../../api/DnD/characterGenerator';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import { AnalyticsThunk } from '../../redux/analytics/thunks';
import { useCharacterInfo } from '../../redux/character/selectors';
import { CharacterThunks } from '../../redux/character/thunks';
import { useAppDispatch } from '../../redux/store';
import { Button } from '../common/Button/Button';
import { TextBox } from '../common/TextBox';
import styles from './CharacterNameSelector.module.css';

export const CharacterNameSelector: React.FC = () => {
  const characterInfo = useCharacterInfo();
  const dispatch = useAppDispatch();

  const submitName = React.useCallback(
    (value: string) => {
      if (!value) {
        return;
      }

      dispatch(CharacterThunks.setCharacter({ name: value }));
    },
    [dispatch],
  );

  const submitRandomName = React.useCallback(() => {
    submitName(getRandomName());
    dispatch(AnalyticsThunk.nameRandomizeClicked());
  }, [dispatch, submitName]);

  return (
    <div className={styles.container}>
      <TextBox
        autoFocus={false}
        submitOnBlur={true}
        resetOnEmpty={true}
        spellCheck={false}
        className={styles.heroName}
        initialValue={characterInfo.name}
        onSubmit={submitName}
      />
      <Button tooltip="Generate a new name" onClick={submitRandomName}>
        <RefreshIcon width={24} height={24} />
      </Button>
    </div>
  );
};
