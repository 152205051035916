import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { v4 as uuidv4 } from 'uuid';
import { isDebug } from '../../utils';

const AMPLITUDE_API_KEY = 'c723bab3ad04aed6bd4ab4a0c34e33a1';

export type AnalyticsApi = {
  getUserId: () => string;
  init: () => void;
  track: (eventInput: string, eventProperties?: Record<string, unknown> | undefined) => void;
};

const getUserId = (): string => {
  let userId = localStorage.getItem('userId');

  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('userId', userId);
  }

  return userId;
};

const createAmplitude = (): AnalyticsApi => {
  const init = (): void => {
    console.log('Amplitude: Init');

    // Create and Install Session Replay Plugin
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0, //NOTE: https://amplitude.com/docs/session-replay/session-replay-plugin#sampling-rate
    });
    amplitude.add(sessionReplayTracking);

    amplitude.init(AMPLITUDE_API_KEY, getUserId(), { cookieOptions: { sameSite: 'None', secure: true } });
  };

  const track = (eventInput: string, eventProperties?: Record<string, unknown> | undefined): void => {
    amplitude.track(eventInput, eventProperties);
  };

  return {
    init,
    track,
    getUserId,
  };
};

const createDebugAnalytics = (): AnalyticsApi => {
  const init = (): void => {
    console.log('Debug Analytics: Init');
  };

  const track = (eventInput: string, eventProperties?: Record<string, unknown> | undefined): void => {
    console.log('Debug Analytics: Track', { eventInput, eventProperties });
  };

  return {
    init,
    track,
    getUserId,
  };
};

export const createAnalytics = (): AnalyticsApi => (isDebug() ? createDebugAnalytics() : createAmplitude());
