import Immutable from 'immutable';
import { getRandomItem } from '../../utils/arrayUtils';
import { AbilityType } from './abilities';
import { getRaceModificator, Race } from './races';

export const TOTAL_AVAILABLE_POINTS = 27;

const ABILITY_SCORE_POINTS_COST = new Map<number, number>([
  [8, 0],
  [9, 1],
  [10, 2],
  [11, 3],
  [12, 4],
  [13, 5],
  [14, 7],
  [15, 9],
]);

const getCost = (abilityValue: number) => ABILITY_SCORE_POINTS_COST.get(abilityValue) ?? Number.MAX_VALUE;

const getAvailableToIncreaseAbilities = (
  abilityMap: Map<AbilityType, number> | Immutable.Map<AbilityType, number>,
  remainingPoints: number,
): AbilityType[] => {
  const abilityTypes: AbilityType[] = [];

  abilityMap.forEach((value, type) => {
    const costDiff = getCostOfIncrease(value);
    if (costDiff <= remainingPoints) {
      abilityTypes.push(type);
    }
  });

  return abilityTypes;
};

const getRemainingPointsByValues = (abilityValues: Iterable<number>): number => {
  let remainingPoints = TOTAL_AVAILABLE_POINTS;

  for (const value of abilityValues) {
    remainingPoints -= getCost(value);
  }

  return remainingPoints;
};

const generateAbilityValues = (): Map<AbilityType, number> => {
  const abilityTypes = Object.values(AbilityType);
  const abilityMap = new Map(abilityTypes.map((type) => [type, 8]));

  while (true) {
    const remainingPoints = getRemainingPointsByValues(abilityMap.values());
    const abilitiesToIncrease = getAvailableToIncreaseAbilities(abilityMap, remainingPoints);

    if (abilitiesToIncrease.length === 0) {
      break;
    }

    const abilityType = getRandomItem(abilitiesToIncrease);
    const abilityValue = abilityMap.get(abilityType);

    if (!abilityValue) {
      throw new Error(`Cannot find abilityvalue for ${abilityType}`);
    }

    abilityMap.set(abilityType, abilityValue + 1);
  }

  return abilityMap;
};

export const getCostOfIncrease = (abilityValue: number) => getCost(abilityValue + 1) - getCost(abilityValue);

export const getRandomAbilities = (): Immutable.Map<AbilityType, number> => {
  const abilityToValue = generateAbilityValues();
  return Immutable.Map(abilityToValue);
};

export const getRemainingPoints = (abilities: Immutable.Map<AbilityType, number>) =>
  getRemainingPointsByValues(abilities.valueSeq());

export const getAbilityMod = (abilityValue: number): number => Math.floor((abilityValue - 10) / 2);

export const calcAbilityModifier = (baseValue: number, type: AbilityType, race: Race) => {
  const raceMod = getRaceModificator(race, type);
  const abilityModifier = getAbilityMod(baseValue + raceMod);

  return abilityModifier;
};
