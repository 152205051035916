import Immutable from 'immutable';
import { AbilityType } from './abilities';

export enum Race {
  Dragonborn = 'dragonborn',
  HillDwarf = 'hill_dwarf',
  MountainDwarf = 'mountain_dwarf',
  HighElf = 'high_elf',
  WoodElf = 'wood_elf',
  DarkElf = 'dark_elf',
  ForestGnome = 'forest_gnome',
  RockGnome = 'rock_gnome',
  HalfElf = 'half_elf',
  HalfOrc = 'half_orc',
  StoutHalfling = 'stout_halfling',
  LightfootHalfling = 'lightfoot_halfling',
  Human = 'human',
  Tiefling = 'tiefling',
}

export const RaceMods = Immutable.Map<Race, Immutable.Map<AbilityType, number>>([
  [
    Race.Dragonborn,
    Immutable.Map<AbilityType, number>([
      [AbilityType.STR, +2],
      [AbilityType.CHA, +1],
    ]),
  ],
  [
    Race.HillDwarf,
    Immutable.Map<AbilityType, number>([
      [AbilityType.CON, +2],
      [AbilityType.WIS, +1],
    ]),
  ],
  [
    Race.MountainDwarf,
    Immutable.Map<AbilityType, number>([
      [AbilityType.STR, +2],
      [AbilityType.CON, +2],
    ]),
  ],
  [
    Race.HighElf,
    Immutable.Map<AbilityType, number>([
      [AbilityType.DEX, +2],
      [AbilityType.INT, +1],
    ]),
  ],
  [
    Race.WoodElf,
    Immutable.Map<AbilityType, number>([
      [AbilityType.DEX, +2],
      [AbilityType.WIS, +1],
    ]),
  ],
  [
    Race.DarkElf,
    Immutable.Map<AbilityType, number>([
      [AbilityType.DEX, +2],
      [AbilityType.CHA, +1],
    ]),
  ],
  [
    Race.ForestGnome,
    Immutable.Map<AbilityType, number>([
      [AbilityType.INT, +2],
      [AbilityType.DEX, +1],
    ]),
  ],
  [
    Race.RockGnome,
    Immutable.Map<AbilityType, number>([
      [AbilityType.INT, +2],
      [AbilityType.CON, +1],
    ]),
  ],
  [
    Race.HalfElf,
    Immutable.Map<AbilityType, number>([
      [AbilityType.CHA, +2],
      [AbilityType.INT, +1],
    ]),
  ],
  [
    Race.HalfOrc,
    Immutable.Map<AbilityType, number>([
      [AbilityType.STR, +2],
      [AbilityType.CON, +1],
    ]),
  ],
  [
    Race.StoutHalfling,
    Immutable.Map<AbilityType, number>([
      [AbilityType.DEX, +2],
      [AbilityType.CON, +1],
    ]),
  ],
  [
    Race.LightfootHalfling,
    Immutable.Map<AbilityType, number>([
      [AbilityType.DEX, +2],
      [AbilityType.CHA, +1],
    ]),
  ],
  [
    Race.Tiefling,
    Immutable.Map<AbilityType, number>([
      [AbilityType.CHA, +2],
      [AbilityType.INT, +1],
    ]),
  ],
  [
    Race.Human,
    Immutable.Map<AbilityType, number>([
      [AbilityType.CHA, +1],
      [AbilityType.CON, +1],
      [AbilityType.DEX, +1],
      [AbilityType.INT, +1],
      [AbilityType.STR, +1],
      [AbilityType.WIS, +1],
    ]),
  ],
]);

export const getRaceModificator = (race: Race, abilityType: AbilityType): number =>
  RaceMods.get(race)?.get(abilityType) ?? 0;

export const toRaceName = (race: Race): string => {
  switch (race) {
    case Race.Dragonborn:
      return 'Dragonborn';
    case Race.HillDwarf:
      return 'Hill Dwarf';
    case Race.MountainDwarf:
      return 'Mountain Dwarf';
    case Race.HighElf:
      return 'High Elf';
    case Race.WoodElf:
      return 'Wood Elf';
    case Race.DarkElf:
      return 'Dark Elf (Drow)';
    case Race.ForestGnome:
      return 'Forest Gnome';
    case Race.RockGnome:
      return 'Rock Gnome';
    case Race.HalfElf:
      return 'Half-Elf';
    case Race.HalfOrc:
      return 'Half-Orc';
    case Race.StoutHalfling:
      return 'Stout Halfling';
    case Race.LightfootHalfling:
      return 'Lightfoot Halfling';
    case Race.Human:
      return 'Human';
    case Race.Tiefling:
      return 'Tiefling';
  }
};
