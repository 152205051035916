import React, { useEffect } from 'react';
import { WindowSize, useWindowDimensions } from '../hooks/useWindowDimensions';
import { useTopPanelFromStack } from '../redux/panels/selectors';
import { PanelsThunk } from '../redux/panels/thunks';
import { useShowAvatar } from '../redux/settings/selectors';
import { useAppDispatch } from '../redux/store';
import { CharacterPanel } from './CharacterPanel/CharacterPanel';
import { DicePanel } from './DicePanel/DicePanel';
import { HelpPanel } from './HelpPanel/HelpPanel';
import { InventoryPanel } from './InventoryPanel/InventoryPanel';

const WIDTH_BP = 960; //px

/* TODO: лучше все это отрефакторить и сделать 3 стека панелей. 
leftPanelStack
rightPanelStack
centerPanelStack

в каждый стек можно добавлять панели по имени, имя это просто строка
в разметке иметь что-то типа

<LeftStack>
  <Panel name="hero">
    <HeroPanelContent />
  </Panel>
  <Panel name="help">
    <HelpPanelContent />
  </Panel>
</LeftStack>

<CentralStack>
  <Panel name="cards">
    <Inventory />
  </Panel>
</CentralStack>

<RightStack>
  <Panel name="dices">
    <DicesPanelContent />
  </Panel>
</RightStack>

и открывать закрывать панели так

dispatch(PanelAction.push(LeftPanel, "hero"));
dispatch(PanelAction.pop(LeftPanel));

компоненты Panel просто будут смотреть совпадает ли их имя с именем в редаксе и в зависимости от этого будут отображаться
надо только учесть, чтобы в стеках имена не пересекались, можно добавлять префикс к имени панели, например, 
или через ContextApi передавать в панель информацию о том, в каком она стеке, тогда имена панелей в разных стеках могут повтарятся
 */

export const Panels: React.FC = () => {
  const dispatch = useAppDispatch();
  const windowSize = useWindowDimensions();
  const showAvatar = useShowAvatar();
  const fitsBothPanels = canFitBothPanels(windowSize);

  const leftTopPanel = useTopPanelFromStack('left');
  const rightTopPanel = useTopPanelFromStack('right');
  const fullScreenTopPanel = useTopPanelFromStack('fullscreen');

  /* NOTE: Instead of code in useEffect, another way dealing with screen size change is that:
   * we can just not render visuals of dices panel when both panels doesn't fit,
   * but in this case after closing hero panel, dice panel appears back. Not sure what is better.
   */

  useEffect(() => {
    //NOTE: If both panels are visible and window size cannot contain them anymore, let's hide right panel stack.
    if (!fitsBothPanels && leftTopPanel && rightTopPanel) {
      dispatch(PanelsThunk.popPanel('right'));
    }
  }, [dispatch, fitsBothPanels, leftTopPanel, rightTopPanel]);

  //NOTE: top panels overflow all other panels and make them invisible
  if (fullScreenTopPanel) {
    switch (fullScreenTopPanel) {
      default:
        throw Error(`Panel ${fullScreenTopPanel} cannot be pushed as FullScreen panel`);
    }
  }

  let leftPanelElement: React.ReactNode | undefined = undefined;
  let rightPanelElement: React.ReactNode | undefined = undefined;

  //TODO: сейчас каждая панель сама определяет с какой стороны ей рендериться, но вообще круто было бы чтобы мы могли запушить любую панель в любой стек
  if (leftTopPanel) {
    switch (leftTopPanel) {
      case 'hero':
        leftPanelElement = (
          <CharacterPanel
            showAvatar={showAvatar}
            panelPlacement={fitsBothPanels ? 'left' : 'bottomFullHeight'}
            onDismiss={() => dispatch(PanelsThunk.popPanel('left'))}
          />
        );
        break;
      case 'help':
        leftPanelElement = (
          <HelpPanel
            panelPlacement={fitsBothPanels ? 'left' : 'bottomFullHeight'}
            onDismiss={() => dispatch(PanelsThunk.popPanel('left'))}
          />
        );
        break;
      case 'inventory':
        leftPanelElement = (
          <InventoryPanel
            key="cardsPanel"
            panelPlacement={fitsBothPanels ? 'left' : 'bottomFullHeight'}
            onDismiss={() => dispatch(PanelsThunk.popPanel('left'))}
          />
        );
        break;
      default:
        throw Error(`Panel ${leftTopPanel} cannot be pushed to the Left yet`);
    }
  }

  if (rightTopPanel) {
    switch (rightTopPanel) {
      case 'dices':
        rightPanelElement = (
          <DicePanel
            panelPlacement={fitsBothPanels ? 'right' : 'bottom'}
            onDismiss={() => dispatch(PanelsThunk.popPanel('right'))}
          />
        );
        break;
      default:
        throw Error(`Panel ${rightTopPanel} cannot be pushed to the Right yet`);
    }
  }

  return (
    <>
      {leftPanelElement}
      {rightPanelElement}
    </>
  );
};

const canFitBothPanels = ({ width, height }: WindowSize): boolean => width >= WIDTH_BP && width > height;
