import { AppDispatch, AppState, AppThunkAction, CardInfo, MutableThunkState } from '../state';
import { CardAction } from './reducer';
import { selectAvailableCardsByType } from './selectors';

const loadCards =
  (): AppThunkAction =>
  async (dispatch: AppDispatch, getState: () => AppState, { cardsStorage }: MutableThunkState): Promise<void> => {
    if (getState().cards.cards.count() > 0) {
      return;
    }

    const cards = await cardsStorage.getAllCardsAsync('cards.json');

    if (cards) {
      dispatch(CardAction.initCards(cards));
    }
  };

const setFilter =
  (typeFilter: string | undefined, selectedCard: CardInfo | undefined): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState): void => {
    const filteredItems = selectAvailableCardsByType(getState(), typeFilter);

    const shouldResetFilter = !!selectedCard && !filteredItems.contains(selectedCard);

    //NOTE: selected card should be in filtered items; otherwise we reset the filter
    dispatch(CardAction.setTypeFilter(shouldResetFilter ? undefined : typeFilter));
    dispatch(CardAction.setSelectedCard(selectedCard));
  };

export const CardThunk = {
  loadCards,
  setFilter,
};
