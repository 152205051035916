import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { selectCharacterInfo } from '../character/selectors';
import { selectCurrentTag } from '../settings/selectors';
import { AppState, CardInfo, CharacterInfo } from '../state';

export const selectCards = (state: AppState): Immutable.List<CardInfo> => state.cards.cards;

export const selectAvailableCards = createSelector(
  [selectCharacterInfo, selectCards, selectCurrentTag],
  (characterInfo: CharacterInfo, cards: Immutable.List<CardInfo>, currentTag?: string): Immutable.List<CardInfo> => {
    const characterCards = cards
      .filter(({ tags, classes, races, charnames }) => {
        return !!(
          (!tags || tags.some((tag) => tag === currentTag)) &&
          (!classes || classes.some((cl) => cl === characterInfo.class)) &&
          (!races || races.some((race) => race === characterInfo.race)) &&
          (!charnames || charnames.some((charname) => charname.toLowerCase() === characterInfo.name.toLowerCase()))
        );
      })
      .sortBy((card) => card.title);

    return characterCards;
  },
);

export const selectAvailableTags = createSelector([selectCards], (cards: Immutable.List<CardInfo>) => {
  const tagSet = new Set<string>();
  cards.forEach((card) => card.tags?.forEach((tag) => tag && tagSet.add(tag)));
  return [...tagSet].sort();
});

export const selectAvailableCardsCount = createSelector(
  [selectAvailableCards],
  (availableCards: Immutable.List<CardInfo>): number => {
    return availableCards.count();
  },
);

export const selectAvailableCardTypes = createSelector(
  [selectAvailableCards],
  (availableCards: Immutable.List<CardInfo>): string[] => {
    const cardTypes = new Set<string>();

    for (const card of availableCards) {
      card.type && cardTypes.add(card.type);
    }

    return [...cardTypes];
  },
);

export const selectAvailableCardsByType = createSelector(
  [selectAvailableCards, (state: AppState, entityType?: string) => entityType],
  (cards: Immutable.List<CardInfo>, entityType?: string) => {
    return entityType ? cards.filter((card) => card.type === entityType) : cards;
  },
);

export const selectSelectedCard = (state: AppState): CardInfo | undefined => state.cards.selectedCard;

export const selectItemTypeFilter = (state: AppState): string | undefined => state.cards.itemTypeFilter;

export const useAvailableCards = (): Immutable.List<CardInfo> => useSelector(selectAvailableCards);
export const useAvailableTags = (): string[] => useSelector(selectAvailableTags);
export const useAvailableCardsCount = (): number => useSelector(selectAvailableCardsCount);
export const useAvailableCardTypes = (): string[] => useSelector(selectAvailableCardTypes);
export const useAvailableCardsByType = (entityType?: string) =>
  useSelector((state: AppState) => selectAvailableCardsByType(state, entityType));
export const useSelectedCard = (): CardInfo | undefined => useSelector(selectSelectedCard);
export const useItemTypeFilter = (): string | undefined => useSelector(selectItemTypeFilter);
