import { AnalyticsThunk } from '../analytics/thunks';
import { AppDispatch, AppState, AppThunkAction } from '../state';
import { PanelsAction } from './reducer';
import { PanelStack, PanelType } from './types';

const pushPanel =
  (panelStack: PanelStack, panelType: PanelType): AppThunkAction =>
  (dispatch: AppDispatch): void => {
    dispatch(PanelsAction.pushPanel({ panelStack, panelType }));
    dispatch(AnalyticsThunk.panelOpen(panelType));
  };

const popPanel =
  (panelStack: PanelStack): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState): void => {
    const state = getState();
    const stack = state.panels.stacks.get(panelStack);
    if (stack) {
      const panelToPop: PanelType = stack.last();

      if (panelToPop) {
        dispatch(PanelsAction.popPanel(panelStack));
        dispatch(AnalyticsThunk.panelClosed(panelToPop));
      }
    }
  };

const closePanel =
  (panelStack: PanelStack, panelType: PanelType): AppThunkAction =>
  (dispatch: AppDispatch): void => {
    dispatch(PanelsAction.closePanel({ panelStack, panelType }));
    dispatch(AnalyticsThunk.panelClosed(panelType));
  };

export const PanelsThunk = {
  pushPanel,
  popPanel,
  closePanel,
};
