import thunk from 'redux-thunk';

import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, legacy_createStore as createStore, Store } from 'redux';
import { createAnalytics } from '../api/Analytics/analytics';
import { createCardsStorage } from '../api/cardsStorage';
import { createHeroStorage } from '../api/heroStorage';
import { createSettingsStorage } from '../api/settingsStorage';
import { createCardsReducer } from './cards/reducer';
import { createCharacterReducer } from './character/reducer';
import { createDicesReducer } from './dices/reducer';
import { createPanelsReducer } from './panels/reducer';
import { createSettingsReducer } from './settings/reducer';
import { AppActions, AppDispatch, AppState, MutableThunkState } from './state';

export const createReduxStore = (): Store<AppState, AppActions> => {
  const heroStorage = createHeroStorage();
  const settingsStorage = createSettingsStorage();

  const analytics = createAnalytics();
  analytics.init();

  const thunkMiddleware = thunk.withExtraArgument<MutableThunkState>({
    heroStorage,
    settingsStorage,
    analytics,
    cardsStorage: createCardsStorage(),
  });

  const middlewares = applyMiddleware(thunkMiddleware);

  const rootReducer = combineReducers<AppState, AppActions>({
    dices: createDicesReducer(),
    settings: createSettingsReducer(settingsStorage),
    character: createCharacterReducer(heroStorage),
    cards: createCardsReducer(),
    panels: createPanelsReducer(),
  });

  return createStore(rootReducer, middlewares);
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
