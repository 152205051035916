import { Avatars } from '../../avatars';
import { CharacterInfo } from '../../redux/state';
import { getRandomItem } from '../../utils/arrayUtils';
import { HeroClass } from './classes';
import { HeroNames } from './heroNames';
import { Race } from './races';

export const getRandomName = () => `${getRandomItem(HeroNames)} ${getRandomItem(HeroNames)}`;

export const getRandomCharacterInfo = (): CharacterInfo => ({
  class: getRandomItem(Object.values(HeroClass)),
  name: getRandomName(),
  avatarFileName: getRandomItem(Avatars),
  race: getRandomItem(Object.values(Race)),
  level: 1,
});
