import React from 'react';
import { Avatars, getFullSizeAvatar } from '../avatars';
import { Constants } from '../constants';
import { useImagePreloader } from '../hooks/useImagePreloader';

export const AvatarPreloader: React.FC = () => {
  const { imagesPreloaded } = useImagePreloader(Avatars.map((name) => getFullSizeAvatar(name)));

  return <></>;
};

export const ItemsPreloader: React.FC = () => {
  const { imagesPreloaded } = useImagePreloader(Constants.Items);

  return <></>;
};
