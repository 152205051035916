import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { createShareUrl } from '../../api/urlCharacterSerializer';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { AnalyticsThunk } from '../../redux/analytics/thunks';
import { useCharacterState } from '../../redux/character/selectors';
import { CharacterThunks } from '../../redux/character/thunks';
import { CharacterInfo } from '../../redux/state';
import { useAppDispatch } from '../../redux/store';
import { Button } from '../common/Button/Button';
import styles from './ShareCharacterButton.module.css';
import { ShareCharacterDialog } from './ShareCharacterDialog';

export const ShareCharacterButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const character = useCharacterState();
  const [shareUrl, setShareUrl] = React.useState(createShareUrl(character, window));
  const [dialogVisible, setDialogVisible] = React.useState(false);

  React.useEffect(() => {
    setShareUrl(createShareUrl(character, window));
  }, [character]);

  const shareClick = async () => {
    dispatch(AnalyticsThunk.shareCharacterClicked());

    const shared = isMobile && (await tryShare(shareUrl, character.characterInfo));

    if (!shared) {
      setDialogVisible(true);
    }
  };

  return (
    <>
      <Button className={styles.shareButton} onClick={shareClick} tooltip="Share Character">
        <ShareIcon width={24} height={24} />
      </Button>

      {dialogVisible && (
        <ShareCharacterDialog
          characterUrl={shareUrl}
          onCopyLink={() => {
            dispatch(CharacterThunks.copyShareCharacterUrl());
            setDialogVisible(false);
          }}
          onDismiss={() => setDialogVisible(false)}
        />
      )}
    </>
  );
};

const tryShare = async (shareUrl: string, characterInfo: CharacterInfo): Promise<boolean> => {
  try {
    await navigator.share({
      // eslint-disable-next-line max-len
      title: `d20gate.com - ${characterInfo.name} ${characterInfo.race} ${characterInfo.class}`,
      url: shareUrl,
    });
  } catch (err) {
    if (`${err}`.includes('AbortError')) {
      return true; //NOTE: user cancelled sharing by closing system dialog
    }

    return false;
  }

  return true;
};
