import { DiceType } from '../../../api/DnD/dices';
import { DiceDescription, DiceInfo, DiceOptions } from '../diceTypes';
import { createDiceTextures, createGeometry, createShape } from '../utils';

const createDescription = (): DiceDescription => {
  const d4FaceTexts: number[][][] = [
    [[], [0, 0, 0], [2, 4, 3], [1, 3, 4], [2, 1, 4], [1, 2, 3]],
    [[], [0, 0, 0], [2, 3, 4], [3, 1, 4], [2, 4, 1], [3, 2, 1]],
    [[], [0, 0, 0], [4, 3, 2], [3, 4, 1], [4, 2, 1], [3, 1, 2]],
    [[], [0, 0, 0], [4, 2, 3], [1, 4, 3], [4, 1, 2], [1, 3, 2]],
  ];

  const desc: DiceDescription = {
    type: DiceType.d4,
    tab: -0.1,
    af: (Math.PI * 7) / 6,
    chamfer: 0.96,
    vertices: [
      [1, 1, 1],
      [-1, -1, 1],
      [-1, 1, -1],
      [1, -1, -1],
    ],
    faces: [
      [1, 0, 2, 1],
      [0, 1, 3, 2],
      [0, 3, 2, 3],
      [1, 2, 3, 4],
    ],
    scaleFactor: 1.2,
    values: 4,
    faceTexts: d4FaceTexts[0],
    textMargin: 1.5,
    mass: 300,
    invertUpside: true,
  };

  return desc;
};

const description = createDescription();

const options: DiceOptions = {
  size: 2.5,
  backColor: '#010e04',
  fontColor: '#ffffff',
};

const textures = createDiceTextures(description, options);
const geometry = createGeometry(options.size, description);

export const D4: DiceInfo = {
  textures,
  description,
  options,
  geometry,
  args: createShape(description, options.size),
};
