import * as React from 'react';
import { FEEDBACK_URL, TWITTER_URL } from '../../constants';
import { AnalyticsThunk } from '../../redux/analytics/thunks';
import { useAppDispatch } from '../../redux/store';
import { SettingsPanel } from '../SettingsPanel/SettingsPanel';
import { Button, LinkButton } from '../common/Button/Button';
import { Icon } from '../common/Icon';
import { Panel } from '../common/Panel/Panel';
import { SiteTheme } from '../common/SiteTheme';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import artLogoLarge from '../../assets/images/artLogoLarge.png';

import styles from './MainMenu.module.css';

type Props = {
  onDismiss: () => void;
};

export const MainMenu: React.FC<Props> = ({ onDismiss }) => {
  const dispatch = useAppDispatch();
  const [showSettings, setShowSettings] = React.useState(false);

  return (
    <>
      <SiteTheme themeColor="#2d3047" />
      {showSettings ? (
        <SettingsPanel
          onDismiss={() => {
            setShowSettings(false);
            onDismiss();
          }}
        />
      ) : (
        <Panel
          panelPlacement="right"
          panelStyle="cover"
          showOverlay={true}
          closePlacement="bottomRight"
          onDismiss={onDismiss}>
          <div className={styles.container}>
            <Icon url={artLogoLarge} width={280} height={280} />
            <div className={styles.title}>d20gate</div>
            <LinkButton
              url={TWITTER_URL}
              className={styles.menuButton}
              onNavigated={() => dispatch(AnalyticsThunk.twitterClicked())}>
              <TwitterIcon width={20} height={20} />
              Twitter
            </LinkButton>
            <LinkButton
              url={FEEDBACK_URL}
              className={styles.menuButton}
              onNavigated={() => dispatch(AnalyticsThunk.feedbackClicked())}>
              Feedback
            </LinkButton>
            <Button className={styles.menuButton} onClick={() => setShowSettings(true)}>
              Settings
            </Button>
          </div>
        </Panel>
      )}
    </>
  );
};
