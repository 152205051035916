import * as React from 'react';
import { DiceAction } from '../../redux/dices/reducer';
import { useRollSuggestions } from '../../redux/settings/selectors';
import { useAppDispatch } from '../../redux/store';
import { fromDicesArray } from '../../utils/diceUtils';
import { RollSuggetion } from '../../redux/state';
import { toNumWithSign } from '../../utils';
import { ToggleSelector } from '../common/ToggleSelector/ToggleSelector';

import styles from './RollSuggestions.module.css';

export const RollSuggestions: React.FC = () => {
  const dispatch = useAppDispatch();
  const rollSuggestions = useRollSuggestions();

  return (
    <>
      {rollSuggestions && rollSuggestions.length > 0 && (
        <div className={styles.diceHistory}>
          <ToggleSelector
            items={rollSuggestions}
            initialSelection={rollSuggestions[0]}
            itemClassName={styles.historyEntry}
            hasAllButton={false}
            onItemSelected={(rollSuggestion) => {
              if (!rollSuggestion) {
                return;
              }

              const dicesToRoll = fromDicesArray(rollSuggestion.dices);
              if (dicesToRoll) {
                dispatch(DiceAction.setDicesToRoll(dicesToRoll));
              }

              if (rollSuggestion.resultModifier) {
                dispatch(DiceAction.setResultModifier(rollSuggestion.resultModifier));
              }

              if (rollSuggestion.rollType) {
                dispatch(DiceAction.setRollType(rollSuggestion.rollType));
              }
            }}
            getItemTitle={createTitle}
            getItemKey={createTitle}
          />
        </div>
      )}
    </>
  );
};

const createTitle = ({ dices, resultModifier, rollType }: RollSuggetion) => {
  let parts = dices.join(' + ');

  if (rollType === 'advantage') {
    parts += ' (adv)';
  } else if (rollType === 'disadvantage') {
    parts += ' (dis)';
  }

  if (resultModifier !== 0) {
    parts += ' ' + toNumWithSign(resultModifier);
  }

  return parts;
};
