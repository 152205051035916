import * as React from 'react';
import styles from './Overlay.module.css';

type Props = {
  onDismiss: () => void;
};

export const Overlay: React.FC<Props> = ({ onDismiss }) => {
  return (
    <div
      className={styles.overlay}
      onClick={onDismiss}
      onTouchEnd={(evt) => {
        evt.preventDefault();
        onDismiss();
      }}
    />
  );
};
