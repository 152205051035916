import { HeroClass, HeroClassMods } from './classes';
import { getMaxDiceValue } from './dices';

export const getInitialHP = (heroClass: HeroClass): number => {
  const diceType = HeroClassMods.get(heroClass)?.HitDice;

  if (!diceType) {
    throw new Error(`Unknown health points for class ${heroClass}`);
  }

  return getMaxDiceValue(diceType);
};
