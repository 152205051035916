import * as React from 'react';

export enum StackDirection {
  Horizontal,
  Vertical,
}

type Props = {
  children: React.ReactNode;
  direction: StackDirection;
  gap?: number | string;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  className?: string | undefined;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
};

export const Stack: React.FC<Props> = ({
  children,
  direction,
  gap,
  justifyContent,
  alignItems,
  className,
  flexWrap,
}) => (
  <div
    className={className}
    style={{
      display: 'flex',
      flexDirection: direction === StackDirection.Horizontal ? 'row' : 'column',
      gap,
      justifyContent,
      alignItems,
      flexWrap,
    }}>
    {children}
  </div>
);
