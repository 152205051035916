import * as React from 'react';
import { ReactComponent as DownIcon } from '../../../assets/icons/down.svg';
import { ReactComponent as UpIcon } from '../../../assets/icons/up.svg';
import { Button } from '../Button/Button';
import styles from './CountSelector.module.css';

type Props = {
  text?: string;
  value: number;
  onValueChanged: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  children?: React.ReactNode;
  orientation?: 'horizontal' | 'vertical';
};

const horizontalStyles = {
  container: `${styles.container} ${styles.horizontal}`,
  incButton: `${styles.button} ${styles.rightButton}`,
  decButton: `${styles.button} ${styles.leftButton}`,
};

const verticalStyles = {
  container: `${styles.container} ${styles.vertical}`,
  incButton: `${styles.button} ${styles.topButton}`,
  decButton: `${styles.button} ${styles.bottomButton}`,
};

export const CountSelector: React.FC<Props> = ({
  text,
  value,
  minValue = 0,
  maxValue = 100,
  children,
  onValueChanged,
  orientation = 'vertical',
}) => {
  const countStyles = orientation === 'vertical' ? verticalStyles : horizontalStyles;

  return (
    <div className={countStyles.container}>
      <Button
        className={countStyles.incButton}
        disabled={value >= maxValue}
        onClick={() => value < maxValue && onValueChanged(value + 1)}>
        <UpIcon width={24} height={42} />
      </Button>

      <div className={styles.countContainer}>
        {children ? (
          children
        ) : (
          <>
            <div>{text}</div>
            <div className={styles.count}>{value}</div>
          </>
        )}
      </div>

      <Button
        className={countStyles.decButton}
        disabled={value <= minValue}
        onClick={() => value > minValue && onValueChanged(value - 1)}>
        <DownIcon width={24} height={42} />
      </Button>
    </div>
  );
};
