import * as React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { Button } from '../common/Button/Button';
import { Dialog } from '../common/Dialog/Dialog';

import styles from './ShareCharacterDialog.module.css';

type Props = {
  characterUrl: string;
  onCopyLink: () => void;
  onDismiss: () => void;
};

export const ShareCharacterDialog: React.FC<Props> = ({ characterUrl, onCopyLink, onDismiss }) => {
  return (
    <Dialog onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div>Share Character</div>
          <Button className={styles.closeButton} onClick={onDismiss}>
            <CloseIcon width={12} height={12} title="Close" />
          </Button>
        </div>

        <label htmlFor="urlTextbox">To share character send this link to your friend</label>
        <input
          className={styles.shareUrl}
          type="text"
          id="urlTextbox"
          name="urlTextbox"
          readOnly={true}
          spellCheck={false}
          value={characterUrl}
        />

        <Button className={styles.shareButton} onClick={onCopyLink}>
          Copy Link
        </Button>
      </div>
    </Dialog>
  );
};
