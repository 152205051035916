import React from 'react';
import styles from './BottomToolbar.module.css';

type Props = {
  children: React.ReactNode;
};

export const BottomToolbar: React.FC<Props> = ({ children }) => (
  <div className={styles.container}>
    {React.Children.map(children, (child) => (
      <div className={styles.toolbarItem}>{child}</div>
    ))}
  </div>
);
