import * as React from 'react';
import { useAvailableCards } from '../../redux/cards/selectors';
import { CardThunk } from '../../redux/cards/thunks';
import { usePanels } from '../../redux/panels/selectors';
import { PanelsThunk } from '../../redux/panels/thunks';
import { useAppDispatch } from '../../redux/store';
import { Button } from '../common/Button/Button';
import { Icon } from '../common/Icon';
import { Stack, StackDirection } from '../common/Stack';
import styles from './InventoryPreview.module.css';

const PREVIEW_COUNT = 3;

type Props = {
  mode: 'grid' | 'row';
  itemsCount?: number;
  background?: 'dark' | 'transparent';
};

export const InventoryPreview: React.FC<Props> = ({ mode, itemsCount = PREVIEW_COUNT, background = 'transparent' }) => {
  const dispatch = useAppDispatch();
  const cards = useAvailableCards();
  const cardsCount = cards.count();
  const panelState = usePanels();

  const isInventoryOpen = !!panelState.stacks.get('left')?.contains('inventory');

  if (cardsCount === 0) {
    return null;
  }

  const cardsLeft = cardsCount - itemsCount;

  const buttonClassName = background === 'transparent' ? styles.panel : `${styles.panel} ${styles.darkBackground}`;

  const openInventory = () => isInventoryOpen === false && dispatch(PanelsThunk.pushPanel('left', 'inventory'));

  const ItemsElement = (
    <>
      {cards.take(itemsCount).map((card) => (
        <Button key={card.id} onClick={() => dispatch(CardThunk.setFilter(undefined, card))}>
          <Icon url={card.image || 'images/inventory/scroll.png'} width={28} height={28} />
        </Button>
      ))}
      {cardsLeft > 0 && (
        <Button key="cardsLeft" onClick={() => dispatch(CardThunk.setFilter(undefined, undefined))}>
          <div className={styles.cardsLeft}>+{cardsLeft}</div>
        </Button>
      )}
    </>
  );

  return (
    <div className={buttonClassName} onClick={openInventory}>
      {mode === 'grid' ? (
        <div className={styles.conteiner}>{ItemsElement}</div>
      ) : (
        <Stack direction={StackDirection.Horizontal} justifyContent="center" alignItems="center" gap={8}>
          {ItemsElement}
        </Stack>
      )}
    </div>
  );
};
