const FULL_SIZE_AVATARS_PATH = 'images/avatars/';
const AVATAR_FACES_PATH = 'images/avatars/faces/';

const Avatars: string[] = [
  'female human.png',
  'male human.png',
  'female human 2.png',
  'male human 2.png',
  'female human 3.png',
  'male human 3.png',
  'female dark elf.png',
  'male dark elf.png',
  'female dragonborn.png',
  'male dragonborn.png',
  'female forest gnome.png',
  'male forest gnome.png',
  'female half elf.png',
  'male half elf.png',
  'female half orc.png',
  'male half orc.png',
  'female high elf.png',
  'male high elf.png',
  'female hill dwarf.png',
  // 'male hill dwarf.png',
  'male dwarf 2.png',
  'female mountain dwarf.png',
  'male mountain dwarf.png',
  'female lightfoot halfling.png',
  'male lightfoot halfling.png',
  'female rock gnome.png',
  'male rock gnome.png',
  'female stout halfling.png',
  'male stout halfling.png',
  'female tiefling.png',
  'male tiefling.png',
  'female wood elf.png',
  'male wood elf.png',
];

const getAvatarFace = (name: string) => `${AVATAR_FACES_PATH}${name}`;
const getFullSizeAvatar = (name: string) => `${FULL_SIZE_AVATARS_PATH}${name}`;

export { Avatars, getFullSizeAvatar, getAvatarFace };
