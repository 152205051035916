import * as React from 'react';
import { CSSProperties } from 'react';
import { Portal } from '../Portal';
import styles from './Dialog.module.css';

type Props = {
  children?: React.ReactNode;
  onDismiss: () => void;
  contetStyle?: CSSProperties | undefined;
};

export const Dialog: React.FC<Props> = ({ children, contetStyle, onDismiss }) => {
  return (
    <Portal>
      <div className={styles.container}>
        <div
          className={styles.overlay}
          onClick={onDismiss}
          onTouchEnd={(evt) => {
            evt.preventDefault();
            onDismiss();
          }}
        />
        <div className={styles.content} style={contetStyle}>
          {children}
        </div>
      </div>
    </Portal>
  );
};
