import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import { getRandomItem } from '../../utils/arrayUtils';
import dice_roll from '../../assets/sounds/dice_roll.m4a';
import dice_to_dice_1 from '../../assets/sounds/dice_to_dice_1.m4a';
import dice_to_dice_2 from '../../assets/sounds/dice_to_dice_2.m4a';
import dice_to_dice_3 from '../../assets/sounds/dice_to_dice_3.m4a';
import dice_to_dice_4 from '../../assets/sounds/dice_to_dice_4.m4a';
import dice_to_dice_5 from '../../assets/sounds/dice_to_dice_5.m4a';
import dice_to_dice_6 from '../../assets/sounds/dice_to_dice_6.m4a';
import dice_to_mat_1 from '../../assets/sounds/dice_to_mat_1.m4a';
import dice_to_mat_2 from '../../assets/sounds/dice_to_mat_2.m4a';
import dice_to_mat_3 from '../../assets/sounds/dice_to_mat_3.m4a';
import dice_to_mat_4 from '../../assets/sounds/dice_to_mat_4.m4a';
import dice_to_mat_5 from '../../assets/sounds/dice_to_mat_5.m4a';
import dice_to_mat_6 from '../../assets/sounds/dice_to_mat_6.m4a';
import dice_to_mat_7 from '../../assets/sounds/dice_to_mat_7.m4a';
import dice_to_mat_8 from '../../assets/sounds/dice_to_mat_8.m4a';

export type DiceSounds = {
  floorSounds: THREE.Audio<GainNode>[];
  diceSounds: THREE.Audio<GainNode>[];
  startRollSound: THREE.Audio<GainNode>;
};

const DICE_TO_MAT_SOUNDS: string[] = [
  dice_to_mat_1,
  dice_to_mat_2,
  dice_to_mat_3,
  dice_to_mat_4,
  dice_to_mat_5,
  dice_to_mat_6,
  dice_to_mat_7,
  dice_to_mat_8,
];

const DICE_TO_DICE_SOUNDS: string[] = [
  dice_to_dice_1,
  dice_to_dice_2,
  dice_to_dice_3,
  dice_to_dice_4,
  dice_to_dice_5,
  dice_to_dice_6,
];

const START_ROLL_SOUND = dice_roll;

const createSound = async (soundFile: string, listener: THREE.AudioListener): Promise<THREE.Audio<GainNode>> => {
  // create a global audio source
  const sound = new THREE.Audio(listener);
  // load a sound and set it as the Audio object's buffer
  const audioLoader = new THREE.AudioLoader();

  const buffer = await audioLoader.loadAsync(soundFile);
  sound.setBuffer(buffer);
  return sound;
};

const loadDiceSounds = async (audioListener: THREE.AudioListener): Promise<DiceSounds> => {
  const floorSoundsPromises = DICE_TO_MAT_SOUNDS.map((file) => createSound(file, audioListener));
  const diceSoundsPromises = DICE_TO_DICE_SOUNDS.map((file) => createSound(file, audioListener));
  const startRollSoundPromise = createSound(START_ROLL_SOUND, audioListener);

  const [floorSounds, diceSounds, startRollSound] = await Promise.all([
    Promise.all(floorSoundsPromises),
    Promise.all(diceSoundsPromises),
    startRollSoundPromise,
  ]);

  //NOTE: https://github.com/mrdoob/three.js/issues/10404
  const source = audioListener.context.createBufferSource();
  source.connect(audioListener.context.destination);
  source.start();

  return { floorSounds, diceSounds, startRollSound };
};

export const playSound = (sound: THREE.Audio<GainNode>, volume: number, vibrate: boolean) => {
  if (sound.isPlaying) {
    sound.stop();
  }

  //NOTE: round to 2 decimal places
  //https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  const roundedVolume = Math.round((volume + Number.EPSILON) * 100) / 100;

  sound.setVolume(roundedVolume);
  sound.play();

  if (vibrate && navigator?.vibrate) {
    const VIBRATION_MULTIPLIER = 15;
    navigator.vibrate([roundedVolume * VIBRATION_MULTIPLIER]);
  }
};

export const playRandomSound = (sounds: THREE.Audio<GainNode>[], volume: number, vibrate: boolean) => {
  const sound = getRandomItem(sounds);
  playSound(sound, volume, vibrate);
};

export const useDiceSounds = (): DiceSounds | undefined => {
  const camera: THREE.Camera = useThree(({ camera }) => camera);
  const [diceSounds, setDiceSounds] = useState<DiceSounds | undefined>();

  useEffect(() => {
    let isMounted = true;
    let audioListener: THREE.AudioListener | undefined = undefined;

    const onInitSoundsAsync = async () => {
      // console.log('LOADING DICE SOUNDS');

      // NOTE: Interaction happened, we can unsubscribe now
      unsubscribeFromInteraction(onInitSoundsAsync);

      // create an AudioListener and add it to the camera
      // in the browser sounds should be initialized only after user interactions, otherwise they will not play
      audioListener = new THREE.AudioListener();

      const sounds = await loadDiceSounds(audioListener);
      camera.add(audioListener);

      if (isMounted) {
        // console.log('DICE SOUNDS LOADED');
        setDiceSounds(sounds);
      }
    };

    //NOTE: sounds should be initialized only after user interaction
    //https://github.com/mrdoob/three.js/issues/10404
    subscribeToInteraction(onInitSoundsAsync);

    return () => {
      unsubscribeFromInteraction(onInitSoundsAsync);

      audioListener && camera.remove(audioListener);
      isMounted = false;
    };
  }, [camera]);

  return diceSounds;
};

const subscribeToInteraction = (callback: () => Promise<void>) => {
  window.addEventListener('touchstart', callback);
  window.addEventListener('click', callback);
  window.addEventListener('mousedown', callback);
};

const unsubscribeFromInteraction = (callback: () => Promise<void>) => {
  window.removeEventListener('touchstart', callback);
  window.removeEventListener('click', callback);
  window.removeEventListener('mousedown', callback);
};
