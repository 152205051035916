import * as THREE from 'three';
import { DiceOptions } from './diceTypes';

export type FaceTextureFunction = (
  faceText: string | number[],
  options: DiceOptions,
  textMargin: number,
) => THREE.Texture | null;

const calculateTextureSize = (approx: number) => {
  return Math.max(128, Math.pow(2, Math.floor(Math.log(approx) / Math.log(2))));
};

//TODO: this could be optimized, we can create atlas of textures based on size and dice type
export const createFaceTexture: FaceTextureFunction = (
  faceText: string | number[],
  { fontColor, backColor, size }: DiceOptions,
  textMargin: number,
): THREE.Texture | null => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    return null;
  }

  const ts = calculateTextureSize(size / 2 + size * textMargin) * 2;
  canvas.width = canvas.height = ts;
  context.font = ts / (1 + 2 * textMargin) + 'pt Arial';
  context.fillStyle = backColor;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillStyle = fontColor;

  if (Array.isArray(faceText)) {
    for (const text of faceText) {
      context.fillText(text.toString(), canvas.width / 2, canvas.height / 2 - ts * 0.3);
      context.translate(canvas.width / 2, canvas.height / 2);
      context.rotate((Math.PI * 2) / 3);
      context.translate(-canvas.width / 2, -canvas.height / 2);
    }
  } else {
    context.fillText(faceText, canvas.width / 2, canvas.height / 2);
  }

  const texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;
  return texture;
};
