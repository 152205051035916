import { DiceType } from '../../../api/DnD/dices';
import { DiceDescription, DiceInfo, DiceOptions } from '../diceTypes';
import { FACE_TEXTS } from '../r3fConstants';
import { createDiceTextures, createGeometry, createShape } from '../utils';

const createDescription = (): DiceDescription => {
  const vertices: [number, number, number][] = [];
  for (let i = 0, b = 0; i < 10; ++i, b += (Math.PI * 2) / 10) {
    vertices.push([Math.cos(b), Math.sin(b), 0.105 * (i % 2 ? 1 : -1)]);
  }
  vertices.push([0, 0, -1]);
  vertices.push([0, 0, 1]);

  const desc: DiceDescription = {
    type: DiceType.d10,
    tab: 0,
    af: (Math.PI * 6) / 5,
    chamfer: 0.945,
    vertices,
    faces: [
      [5, 7, 11, 0],
      [4, 2, 10, 1],
      [1, 3, 11, 2],
      [0, 8, 10, 3],
      [7, 9, 11, 4],
      [8, 6, 10, 5],
      [9, 1, 11, 6],
      [2, 0, 10, 7],
      [3, 5, 11, 8],
      [6, 4, 10, 9],
      [1, 0, 2, -1],
      [1, 2, 3, -1],
      [3, 2, 4, -1],
      [3, 4, 5, -1],
      [5, 4, 6, -1],
      [5, 6, 7, -1],
      [7, 6, 8, -1],
      [7, 8, 9, -1],
      [9, 8, 0, -1],
      [9, 0, 1, -1],
    ],
    scaleFactor: 0.9,
    values: 10,
    faceTexts: FACE_TEXTS,
    textMargin: 1.0,
    mass: 350,
  };

  return desc;
};

const description = createDescription();

const options: DiceOptions = {
  size: 2.5,
  backColor: '#2d3047',
  fontColor: '#ffffff',
};

const textures = createDiceTextures(description, options);
const geometry = createGeometry(options.size, description);

export const D10: DiceInfo = {
  textures,
  description,
  options,
  geometry,
  args: createShape(description, options.size),
};
