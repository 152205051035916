import * as React from 'react';
import { openUrl } from '../../../utils';
import styles from './Button.module.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void;
};

type ButtonProps = Props & {
  onClick?: () => void;
};

export const Button: React.FC<ButtonProps> = ({ children, className, style, disabled, onClick, tooltip }) => (
  <div className={getClassName(className, disabled)} style={style} onClick={onClick} title={tooltip}>
    {children}
  </div>
);

type LinkButtonProps = Props & {
  url: string;
  onNavigated?: () => void;
};

export const LinkButton: React.FC<LinkButtonProps> = ({
  url,
  tooltip,
  className,
  style,
  disabled,
  children,
  onNavigated,
}) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    className={getClassName(className, disabled)}
    style={style}
    title={tooltip}
    onClick={(evt) => {
      evt.preventDefault();
      openUrl(url);
      onNavigated && onNavigated();
    }}>
    {children}
  </a>
);

const getClassName = (className?: string, disabled?: boolean) => {
  if (!className && !disabled) {
    return styles.button;
  }

  const classNames = [styles.button, className, disabled ? styles.disabled : undefined];
  return classNames.join(' ');
};
